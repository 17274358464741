import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { MessageStatus } from'../objectmodels/messageStatus';
import { Cemetery } from'../objectmodels/cemetery';

@Injectable({
  providedIn: 'root'
})
export class CemeteryService {

  private searchCemeteriesURL = 'https://www.stone-simple.com/WSLIVE_RICHARDSON/cemeteries/search_cemeteries_rII.php';

  constructor(
    private http: HttpClient
  ) { }

  
  searchCemeteries(searchTerm: string): Observable<Cemetery[]> {
    return this.http.post<Cemetery[]>(`${this.searchCemeteriesURL}`, { searchFor: searchTerm })
        .pipe(map((res) => {
          if(res) {
            return res;
          } else {
            return null;
          }
        }));
  }

}
