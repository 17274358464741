<!-- Start -->
<section class="bg-half-170 d-table bg-primary w-100" id="home" style="background-image: url('assets/images/referral.jpg');">
  <div class="container" #topTarget>
    <div class="row mt-5 justify-content-center">
      <div class="col">
        <div class="pages-heading title-heading text-center">
          <h4 class="display-6 text-white title-dark mb-4 fw-bold">Booking Request</h4>
        </div>
      </div><!--end col-->
    </div><!--end row-->
  </div><!--end container-->
</section><!--end section-->
<section class="section paddSoft">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <p class="text-muted-50 mx-auto">
          You are requesting an appointment at the following location:<br>
          <span style="color:darkred;font-size: 1.1em;font-weight: bold;">{{location}}</span> 
        </p>
        
        <form [formGroup]="bookingForm" (ngSubmit)="sendBookingRequest()" class="needs-validation" name="bookingForm">
          <div class="row g-3">
    <div class="col-sm-12">
      <label for="custName" class="form-label">Your Name <span style="color:#7F1517;">*REQUIRED</span></label>
      <input type="text" formControlName="custName" placeholder="Your Name" class="form-control" />
    </div>

    <div class="col-sm-6">
      <label for="custEmail" class="form-label">Email</label>
      <input type="email" formControlName="custEmail" placeholder="Email" class="form-control" />
    </div>

    <div class="col-sm-6">
      <label for="custPhone" class="form-label">Phone</label>
      <input type="text" formControlName="custPhone" placeholder="111-111-1111" class="form-control" />
    </div>

    <div class="col-sm-12">
      <label for="cemeteryName" class="form-label">Cemetery Name</label>
      <input type="text" formControlName="cemeteryName" placeholder="Cemetery Name" class="form-control" />
    </div>

            <div class="col-lg-12" style="margin-bottom: 16px;">
              Choose a product / service
            </div>

            <div class="row equal-height">
              <div class="col-lg-3" (click)="selectService('oneonone')">
                <div class="shadow-sm p-3 mb-2 rounded" [class.selected]="selectedService === 'oneonone'">
                  <img src="assets/images/1on1.jpg" style="max-width: 95%;height: auto;display: block; margin-bottom: 25px;">
                  1 on 1 monument design consultation with one of our experts in person.
                </div>
              </div>
              <div class="col-lg-3" (click)="selectService('zoom')">
                <div class="shadow-sm p-3 mb-2 rounded" [class.selected]="selectedService === 'zoom'">
                  <img src="assets/images/Zoom-LogoII.jpg" style="max-width: 95%;height: auto;display: block; margin-bottom: 25px;">
                  Virtual monument design meeting via Zoom with one of our experts.
                </div>
              </div>
              <div class="col-lg-3" (click)="selectService('engraving')">
                <div class="shadow-sm p-3 mb-2 rounded" [class.selected]="selectedService === 'engraving'">
                  <img src="assets/images/engrave.jpg" style="max-width: 95%;height: auto;display: block; margin-bottom: 25px;">
                  Engrave an existing Monument
                </div>
              </div>
              <div class="col-lg-3" (click)="selectService('other')">
                <div class="shadow-sm p-3 mb-2 rounded" [class.selected]="selectedService === 'other'">
                  <img src="assets/images/other.jpg" style="max-width: 95%;height: auto;display: block; margin-bottom: 25px;">
                  Other
                </div>
              </div>
            </div>

            <div class="col-sm-12" *ngIf="selectedService === 'oneonone' || selectedService === 'zoom'">
              <p>Select a date {{selected}}, then chose a time slot: <span style="font-size: 0.7em;color: darkred;"><strong>* please note:</strong> the slot you chose may not be available. If not we will reach out to schedule one that works.</span></p>
              <div class="container">
                <div class="flex-container">
                  <mat-form-field class="flex-item" appearance="fill">
                    <mat-label>Select a date</mat-label>
                    <input matInput [matDatepicker]="picker" [formControl]="dateControl" [matDatepickerFilter]="myFilter" [min]="minDate" readonly (dateChange)="date = $event.value">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>

                  <mat-form-field class="flex-item" appearance="fill">
                    <mat-label>Select a time slot</mat-label>
                    <mat-select [formControl]="timeSlotControl" (selectionChange)="timeSlot = $event.value">
                      <mat-option *ngFor="let slot of availableTimeSlots" [value]="slot">{{ slot }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="col-md-12" *ngIf="selectedService === 'engraving' || selectedService === 'other'">
              <div class="mb-3">
                <label class="form-label">Important notes, details etc.</label>
                <div class="form-icon position-relative">
                  <i-feather name="message-circle" class="fea icon-sm icons"></i-feather>
                  <textarea name="comments" id="comments" rows="4" class="form-control ps-5" placeholder="Your Message :" [(ngModel)]="notes"></textarea>
                </div>
              </div>
            </div>
          </div><!--end row-->



<!-- <button class="w-100 btn btn-primary" style="margin-top: 20px;" type="button" (click)="sendBookingRequest()" [disabled]="bookingForm.invalid || sendingRequest">BOOK</button> -->
<button class="w-100 btn btn-primary" style="margin-top: 20px;" type="submit" (click)="sendBookingRequest()" [disabled]="bookingForm.invalid || sendingRequest">BOOK</button>


<!-- <div *ngIf="events.length">
  <h2>Events</h2>
  <ul>
    <li *ngFor="let event of events">
      <h3>{{ event.summary }}</h3>
      <p>{{ event.start.dateTime | date: 'fullDate' }} at {{ event.start.dateTime | date: 'shortTime' }}</p>
      <p>Status: {{ event.status }}</p>
      <p>Color ID: {{ event.colorId }}</p>
    </li>
  </ul>
</div>
<div *ngIf="!events.length">
  <p>No events found.</p>
</div> -->









        </form><!--end form-->
      </div><!--end col-->
    </div><!--end row-->
  </div>
</section>
<!-- End -->
