<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass"
  [ngClass]="{'tagline-height': sliderTopbar == true}">
  <div class="container">
    <!-- Logo container-->
    <div>
      <a class="logo" routerLink="/index" *ngIf="navClass !== 'nav-light'">
        <img src="assets/images/logo-dark.png" class="l-dark logo-light-mode" height="36" alt="">
        <img src="assets/images/logo-light.png" class="logo-dark-mode" height="36" alt="">
      </a>

      <div class="menu-extras">
        <div class="menu-item">
          <!-- Mobile menu toggle-->
          <a class="navbar-toggle" id="isToggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
          <!-- End mobile menu toggle-->
        </div>
      </div>
      <a class="logo" routerLink="/index" *ngIf="navClass === 'nav-light'">
        <img src="assets/images/logo-dark.png" class="l-dark" height="36" alt="">
        <img src="assets/images/logo-light.png" class="l-light" height="36" alt="">
      </a>
    </div>
    <!--end login button-->
    <div id="navigation" *ngIf="Menuoption == 'center'">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li class="has-submenu parent-parent-menu-item">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">MONUMENTS</a><span class="menu-arrow"></span>
          <ul class="submenu megamenu">
            <li>
              <ul>
                <li><a routerLink="/gallery-monument-upright" class="nav-link-ref"><i class="uil uil-cube fs-6 align-middle me-1"></i>upright monuments</a></li>
                <li><a routerLink="/gallery-monument-flat" class="nav-link-ref"><i class="uil uil-cube fs-6 align-middle me-1"></i>flat monuments</a></li>
              </ul>
            </li>
            <li>
              <ul>
                <li><a routerLink="/gallery-monument-slant" class="nav-link-ref"><i class="uil uil-cube fs-6 align-middle me-1"></i>slant monuments</a></li>
                <li><a routerLink="/gallery-monument-bench" class="nav-link-ref"><i class="uil uil-cube fs-6 align-middle me-1"></i>bench monuments</a></li>
              </ul>
            </li>
            <li>
              <ul>
                <li><a routerLink="/gallery-monument-bronze" class="nav-link-ref"><i class="uil uil-cube fs-6 align-middle me-1"></i>bronze monuments</a></li>
                <li><a routerLink="/gallery-monument-custom" class="nav-link-ref"><i class="uil uil-cube fs-6 align-middle me-1"></i>custom monuments</a></li>
              </ul>
            </li>
            <li>
              <ul>
                <li><a routerLink="/gallery-monument-cremation" class="nav-link-ref"><i class="uil uil-cube fs-6 align-middle me-1"></i>cremation monuments</a></li>
                <li><a routerLink="/gallery-monument-civic-jobs" class="nav-link-ref"><i class="uil uil-cube fs-6 align-middle me-1"></i>Civic jobs</a></li>
              </ul>
            </li>
            <li>
              <ul>
                <li><a routerLink="/gallery-monument-mausoleums" class="nav-link-ref"><i class="uil uil-cube fs-6 align-middle me-1"></i>Mausoleums</a></li>
              </ul>
            </li>            
          </ul>
        </li>
        <li class="has-submenu parent-parent-menu-item">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">LOCATIONS</a><span class="menu-arrow"></span>
          <ul class="submenu megamenu">
            <li>
              <ul>
                <li class="megamenu-head"><i class="uil uil-map-marker fs-6 align-middle"></i> MAINE</li>
                <li><a class="nav-link-ref" routerLink="/contact-auburn-me"> Auburn </a></li>
                <li><a class="nav-link-ref" routerLink="/contact-biddeford-me"> Biddeford </a></li>
                <li><a class="nav-link-ref" routerLink="/contact-south-portland-me"> South Portland</a></li>
              </ul>
            </li>
            <li>
              <ul>
                <li class="megamenu-head"><i class="uil uil-map-marker fs-6 align-middle"></i> MASSACHUSETTS</li>
                <li><a class="nav-link-ref" routerLink="/contact-andover-ma"> Andover</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-burlington-ma"> Burlington</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-cambridge-ma"> Cambridge</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-canton-ma"> Canton</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-charlestown-ma"> Charlestown</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-concord-ma"> Concord</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-dedham-ma"> Dedham</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-hingham-ma"> Hingham</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-marshfield-ma"> Marshfield</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-middleton-ma"> Middleton</a></li>
              </ul>
            </li>
            <li>
              <ul>
                <li class="megamenu-head"><i class="uil uil-map-marker fs-6 align-middle"></i> MASSACHUSETTS...</li>
                <li><a class="nav-link-ref" routerLink="/contact-milton-ma"> Milton</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-newton-ma"> Newton</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-peabody-ma"> Peabody</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-quincy-ma"> Quincy</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-somerville-ma"> Somerville</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-wakefield-ma"> Wakefield</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-waltham-ma"> Waltham</a></li>



                <li><a class="nav-link-ref" routerLink="/contact-northborough-ma"> Northborough</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-marlborough-ma"> Marlborough</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-framingham-ma"> Framingham</a></li>


              </ul>
            </li> 
            <li>
              <ul>
                <li class="megamenu-head"><i class="uil uil-map-marker fs-6 align-middle"></i> NEW HAMPSHIRE</li>
                <li><a class="nav-link-ref" routerLink="/contact-concord-nh">CONCORD</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-keene-nh">KEENE</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-rochester-nh">ROCHESTER</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-portsmouth-nh">PORTSMOUTH</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-manchester-nh">BEDFORD</a></li>
                <li><a class="nav-link-ref" routerLink="/contact-nashua-nh">NASHUA</a></li>          
              </ul>
            </li> 
          </ul>
        </li>
        <li class="has-submenu parent-parent-menu-item">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">SERVICES</a><span class="menu-arrow"></span>
          <ul class="submenu">           
            <li>
              <ul>
                <li><a routerLink="/gallery-monument-onsite-engraving" class="nav-link-ref"><i class="uil uil-cube fs-6 align-middle me-1"></i>Onsite Engraving</a></li>
                <li><a routerLink="/gallery-monument-onsite-cleaning" class="nav-link-ref"><i class="uil uil-cube fs-6 align-middle me-1"></i>Onsite Cleaning</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li><a routerLink="/page-book-appointment" class="nav-link-ref">SCHEDULE APPT.</a></li>
        <li><a routerLink="/referral-in-home" class="nav-link-ref">REFERRAL</a></li>
      </ul>
      <!--end navigation menu-->
      <!--end login button-->
      <!--end login button-->
    </div>
    <!--end navigation-->
  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->