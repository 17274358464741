import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { MessageStatus } from'../objectmodels/messageStatus';
import { MessageContact } from'../objectmodels/messageContact';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  private sendMessageURL = 'https://www.stone-simple.com/WSLIVE_RICHARDSON/contact/send_contact_message.php';
  
  constructor(
    private http: HttpClient
  ) { }
  
  sendContactMessage(fromContact: MessageContact): Observable<MessageStatus> {
    return this.http.post<MessageStatus>(`${this.sendMessageURL}`, { fromContact })
        .pipe(map((res) => {
          // alert("GOT HERE");
          if(res) {
            return res;
          } else {
            return null;
          }
        }));
  }

}
