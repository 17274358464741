import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';

import { Referral } from '../objectmodels/referral';
import { MessageStatus } from '../objectmodels/messageStatus';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  private referralPhotoUploadURL = 'https://www.stone-simple.com/WSLIVE_RICHARDSON/referrals/upload_referral_photo.php';

  constructor(private http: HttpClient) { }

  uploadReferralItem(item: File, id:number, token:string, photoNumber:number ): Observable<any> {
    var formData: any = new FormData();
        formData.append("item", item);
        formData.append("refID", id);
        formData.append("refTOKEN", token);
        formData.append("photoNumber", photoNumber);
    return this.http.post(`${this.referralPhotoUploadURL}`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      catchError(this.errorMgmt)
    )
  } 

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

}
