import { Component, OnChanges, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ReferralService } from '../../../../services/referral.service';
import { CookieService } from 'ngx-cookie-service';

import { RefCustomer } from '../../../../objectmodels/refCustomer';
import { Referral } from '../../../../objectmodels/referral';

import * as data from '../../../../../assets/states.json';

@Component({
  selector: 'app-referral-customer',
  templateUrl: './referral-customer.component.html',
  styleUrls: ['./referral-customer.component.css']
})
export class ReferralCustomerComponent implements OnInit {
  
  referral:Referral=null;
  states: any = (data as any).default;

  @Input() refID : number;
  @Input() refTOKEN : string;
  @Output() customerCreated = new EventEmitter<boolean>();

  constructor(
    public referralService: ReferralService,
    private cookieService: CookieService
  ) {
    const currentRef = new Referral(
      this.refID,
      this.refTOKEN,
      ""
    );
    this.referral = currentRef;
  }

  ngOnInit(): void {
  
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.refID) {
      const currentRef = new Referral(
        this.refID,
        this.refTOKEN,
        ""
      );
      this.referral = currentRef;
      // console.dir(this.referral);
    }

    if (changes.refTOKEN) {
      const currentRef = new Referral(
        this.refID,
        this.refTOKEN,
        ""
      );
      this.referral = currentRef;
      // console.dir(this.referral);
    }
  }  

  updateCustomer() {
    let custFName = document.forms["customerForm"]["custFName"].value;
    let custLName = document.forms["customerForm"]["custLName"].value;
    let custRelationshipToDeceased = document.forms["customerForm"]["custRelationshipToDeceased"].value;
    let custPhone = document.forms["customerForm"]["custPhone"].value;
    let custEmail = document.forms["customerForm"]["custEmail"].value;
    let custAddress = document.forms["customerForm"]["custAddress"].value;
    let custCity = document.forms["customerForm"]["custCity"].value;
    let custState = document.forms["customerForm"]["custState"].value;
    let custZip = document.forms["customerForm"]["custZip"].value;    
    const refCustomer = new RefCustomer(
      custFName,
      custLName,
      custPhone,
      custEmail,
      custAddress,
      custCity,
      custState,
      custZip,
      custRelationshipToDeceased,
      false
    );
    // alert(JSON.stringify(refCustomer));
    this.referralService.updateReferralCustomer(this.referral, refCustomer)
    .subscribe(results => {
      if (results) {
        this.customerCreated.emit(true);
      } else {
        this.customerCreated.emit(false);
      }
    });
  } 

}
