export class Booking {
	constructor(
		public bookingID	:	string,
		public location		:	string,
		public custName 	:	string,
		public custEmail 	:	string,
		public custPhone 	:	string,
		public cemName 		:	string,
		public service 		:	string,
		public date 		:	string,
		public timeSlot 	:	string,
		public notes 		:	string
	) {}
}