<!-- Start -->
<section class="bg-half-170 d-table bg-primary w-100" id="home" style="background-image: url('assets/images/monuments/085A8130SMlight.jpg');padding:16em 0;">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-7">
        <div class="title-heading position-relative mt-4" style="z-index: 1;">
          <h1 class="heading mb-3">Richardson Monument</h1>
          <p class="para-desc">"Proudly helping families pay tribute to their loved ones for over 80 years."</p>
          <a routerLink="/page-book-appointment" class="btn btn-primary" style="margin-top:12px;"><i class="uil uil-calender"></i> BOOK APPOINTMENT</a>
          <a routerLink="/contact-us-home" class="btn btn-primary" style="margin-left: 12px;margin-top:12px;"><i-feather name="user" class="fea icon-sm icons"></i-feather> CONTACT US</a>
        </div>
      </div>
    </div>
  </div>
</section><!--end section-->
<!-- End -->

<!-- Features Start -->
<div class="container-fluid mt-4">
  <div class="row">
    <h4 style="width:100%;text-align: center;">EXPLORE OUR PREVIOUS WORK</h4>
    <div class="col-md-4 mt-4">
      <div class="card shop-features border-0 rounded overflow-hidden">
        <img src="assets/images/monuments/upright-thumb.jpg" class="img-fluid" alt="">
        <div class="gallery-title">
          <h4>Uprights</h4>
          <a routerLink="/gallery-monument-upright" class="btn btn-sm btn-soft-primary mt-2 whtMe">VIEW GALLERY</a>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-md-4 mt-4">
      <div class="card shop-features border-0 rounded overflow-hidden">
        <img src="assets/images/monuments/flat-thumb.jpg" class="img-fluid" alt="">
        <div class="gallery-title">
          <h4>Flats</h4>
          <a routerLink="/gallery-monument-flat" class="btn btn-sm btn-soft-primary mt-2 whtMe">VIEW GALLERY</a>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-md-4 mt-4">
      <div class="card shop-features border-0 rounded overflow-hidden">
        <img src="assets/images/monuments/slant-thumb.jpg" class="img-fluid" alt="">
        <div class="gallery-title">
          <h4>Slants</h4>
          <a routerLink="/gallery-monument-slant" class="btn btn-sm btn-soft-primary mt-2 whtMe">VIEW GALLERY</a>
        </div>
      </div>
    </div>
    <!--end col-->
  </div>
  <!--end row-->
</div>
<!--end container-->
<!-- Features End -->
<!-- Features Start -->
<div class="container-fluid mt-4">
  <div class="row">
    <div class="col-md-4 mt-4">
      <div class="card shop-features border-0 rounded overflow-hidden">
        <img src="assets/images/monuments/bench-thumb.jpg" class="img-fluid" alt="">
        <div class="gallery-title">
          <h4>Benches</h4>
          <a routerLink="/gallery-monument-bench" class="btn btn-sm btn-soft-primary mt-2 whtMe">VIEW GALLERY</a>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-md-4 mt-4">
      <div class="card shop-features border-0 rounded overflow-hidden">
        <img src="assets/images/monuments/cremation-thumb.jpg" class="img-fluid" alt="">
        <div class="gallery-title">
          <h4>Cremation</h4>
          <a routerLink="/gallery-monument-cremation" class="btn btn-sm btn-soft-primary mt-2 whtMe">VIEW GALLERY</a>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-md-4 mt-4">
      <div class="card shop-features border-0 rounded overflow-hidden">
        <img src="assets/images/monuments/bronze-thumb.jpg" class="img-fluid" alt="">
        <div class="gallery-title">
          <h4>Bronze</h4>
          <a routerLink="/gallery-monument-bronze" class="btn btn-sm btn-soft-primary mt-2 whtMe">VIEW GALLERY</a>
        </div>
      </div>
    </div>
    <!--end col-->
  </div>
  <!--end row-->
</div>
<!--end container-->
<!-- Features End -->
<!-- Features Start -->
<div class="container-fluid mt-4">
  <div class="row">
    <div class="col-md-4 mt-4">
      <div class="card shop-features border-0 rounded overflow-hidden">
        <img src="assets/images/monuments/custom-thumb.jpg" class="img-fluid" alt="">
        <div class="gallery-title">
          <h4>Custom</h4>
          <a routerLink="/gallery-monument-custom" class="btn btn-sm btn-soft-primary mt-2 whtMe">VIEW GALLERY</a>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-md-4 mt-4">
      <div class="card shop-features border-0 rounded overflow-hidden">
        <img src="assets/images/monuments/civic-thumb.jpg" class="img-fluid" alt="">
        <div class="gallery-title">
          <h4>Civic Jobs</h4>
          <a routerLink="/gallery-monument-civic-jobs" class="btn btn-sm btn-soft-primary mt-2 whtMe">VIEW GALLERY</a>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-md-4 mt-4">
      <div class="card shop-features border-0 rounded overflow-hidden">
        <img src="assets/images/monuments/mausoleum-thumb.jpg" class="img-fluid" alt="">
        <div class="gallery-title">
          <h4>Mausoleums</h4>
          <a routerLink="/gallery-monument-mausoleums" class="btn btn-sm btn-soft-primary mt-2 whtMe">VIEW GALLERY</a>
        </div>
      </div>
    </div>
    <!--end col-->
  </div>
  <!--end row-->
</div>
<!--end container-->
<!-- Features End -->


<!-- Services Start -->
<section class="section" id="greyME">
  <div class="container pb-lg-4 mb-md-5 mb-4">
    <!-- start row -->
    <div class="row justify-content-center" style="margin-top: 20px;">
      <div class="col-12 text-center">
        <div class="card shadow rounded border-0 overflow-hidden">
        <div class="row g-0">
        <div class="col-md-7">
            <div class="card-body">
                <h5 class="card-title">Extensive service area.</h5>
                <p class="card-text">We install and service headstones at cemeteries in almost every state in the country.</p>
                <p class="card-text"><small class="text-muted">Click below to find your cemetery</small></p>
                <a routerLink="/search-cemetery-home" class="btn btn-primary mt-3">Search Cemeteries <i class="uil uil-angle-right-b"></i></a>
            </div>
        </div>
        <div class="col-md-5">
            <img src="assets/images/us-map.jpg" class="img-fluid" alt="...">
        </div>        
        </div>
        </div>

      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->





<!-- About Start -->
<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="section-title">
                  <p class="text-muted"><span class="text-primary fw-bold">Richardson Monument Company, Inc.</span> is a family-owned business that has been in existence for more than 80 years. We provide clients with quality craftsmanship, and most importantly, we provide patience and understanding at a particularly difficult time in their lives.</p><p class="text-muted">Our customers cherish their Richardson Monument Company Cemetery Monuments, Gravestones, and Markers.</p><p class="text-muted"> We also specialize in creating Granite signs for businesses and communities.</p>
                  <a routerLink="/page-book-appointment" class="btn btn-primary"><i class="uil uil-calender"></i> SCHEDULE A DESIGN MEETING</a>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container--> 
    <div class="container" style="margin-top:60px;">
        <div class="row">
            <div class="col-lg-12">
                <div class="section-title">
                    <h4 class="title mb-4">Quality Goes In Before Your Name Goes On</h4>
                      <p class="text-muted">
                      Monuments in many shapes and varieties of stone serve as an enduring testimony to the life of a loved one. The layout and materials are important to the statement, which serves as a lasting reflection of memories. Personalized messages and artwork are etched, hand cut, or sandblasted into the stone to ensure their permanency. All of our work is crafted with the utmost care. Each customer receives a digital design proposal to approve before we actually engrave the stone so that no mistakes are made.
                      </p>
                      <p class="text-muted">
                      <span class="text-primary fw-bold">Richardson Monument Company, Inc.</span> offers honest and reasonable quotes. We guarantee our work and can provide you with a certificate of monument quality if needed. In addition, we ensure that the monument is delivered and installed to the specifications required by the cemetery of choice.   
                      </p> 
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container--> 
</section>
<!--end section-->
<!-- About End -->


<!-- Services Start -->
<section class="section" id="greyME">
  <div class="container pb-lg-4 mb-md-5 mb-4">
    <!-- start row -->
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="card shadow rounded border-0 overflow-hidden">
        <div class="row g-0">
        <div class="col-md-5">
            <img src="assets/images/engraving_home.jpg" class="img-fluid" alt="...">
        </div>
        <div class="col-md-7">
            <div class="card-body">
                <h5 class="card-title">On Site Engraving Services.</h5>
                <p class="card-text">Our skilled craftsmen bring the essence of remembrance to life, on location. Whether it's a name, a date, or a heartfelt message, we carefully inscribe each detail, ensuring a permanent tribute.</p>
                <p class="card-text"><small class="text-muted">Click below to schedule</small></p>
                <a routerLink="/page-book-appointment" class="btn btn-primary"><i class="uil uil-calender"></i> SCHEDULE A DESIGN MEETING</a>
            </div>
        </div>
        </div>
        </div>

      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <div class="container pb-lg-4 mb-md-5 mb-4">
    <!-- start row -->
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="card shadow rounded border-0 overflow-hidden">
        <div class="row g-0">
        <div class="col-md-7">
            <div class="card-body">
                <h5 class="card-title">On Site Cleaning Services.</h5>
                <p class="card-text">Our team rejuvenates each monument with meticulous care and expertise. We restore the luster of time-worn memorials with precision and respect removing debris, algae, and discoloration, revealing the inherent beauty of your orginal monument.</p>
                <a routerLink="/page-book-appointment" class="btn btn-primary"><i class="uil uil-calender"></i> SCHEDULE A CLEANING</a>
            </div>
        </div>          
        <div class="col-md-5">
            <img src="assets/images/cleaning-home.jpg" class="img-fluid" alt="...">
        </div>
        </div>
        </div>

      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->  
</section>
<!--end section-->




<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Services End -->

<!-- Back to top -->
<!-- <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a> -->
<!-- Back to top -->