<div class="card rounded shadow p-4 border-0">
  <form class="needs-validation">
    <div class="row">
        <div class="col-md-12">
            <h5>Pick a Richardson Monument location nearest your customer.</h5>
        </div>
        <div class="col-md-12" style="margin-top: 20px;border-top: 1px solid lightgrey;"><h6>MAINE</h6></div>
        <div class="col-md-6">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MESouthPortland" value="ME - South Portland" [(ngModel)]="location">
                    <label class="form-check-label" for="MESouthPortland">South Portland</label>
                </div>
            </div>  
        </div>
        <div class="col-md-6">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MEBiddeford" value="ME - Biddeford" [(ngModel)]="location">
                    <label class="form-check-label" for="MEBiddeford">Biddeford</label>
                </div>
            </div>   
        </div>
        <div class="col-md-6">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MEAuburn" value="ME - Auburn" [(ngModel)]="location">
                    <label class="form-check-label" for="MEAuburn">Auburn</label>
                </div>
            </div>   
        </div>        

        <div class="col-md-12" style="margin-top: 20px;border-top: 1px solid lightgrey;"><h6>NEW HAMPSHIRE</h6></div>
        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="NHConcord" value="NH - Concord" [(ngModel)]="location">
                    <label class="form-check-label" for="NHConcord">Concord</label>
                </div>
            </div>    
        </div>
        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="NHKeene" value="NH - Keene" [(ngModel)]="location">
                    <label class="form-check-label" for="NHKeene">Keene</label>
                </div>
            </div>    
        </div>
        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="NHRochester" value="NH - Rochester" [(ngModel)]="location">
                    <label class="form-check-label" for="NHRochester">Rochester</label>
                </div>
            </div>    
        </div>
        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="NHPortsmouth" value="NH - Portsmouth" [(ngModel)]="location">
                    <label class="form-check-label" for="NHPortsmouth">Portsmouth</label>
                </div>
            </div>    
        </div>
        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="NHBedford" value="NH - Bedford" [(ngModel)]="location">
                    <label class="form-check-label" for="NHBedford">Bedford</label>
                </div>
            </div>    
        </div>
        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="NHNashua" value="NH - Nashua" [(ngModel)]="location">
                    <label class="form-check-label" for="NHNashua">Nashua</label>
                </div>
            </div>    
        </div>
        



        <div class="col-md-12" style="margin-top: 20px;border-top: 1px solid lightgrey;"><h6>MASSACHUSETTS</h6></div>
        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MAAndover" value="MA - Andover" [(ngModel)]="location">
                    <label class="form-check-label" for="MAAndover">Andover</label>
                </div>
            </div>    
        </div>

        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MABurlington" value="MA - Burlington" [(ngModel)]="location">
                    <label class="form-check-label" for="MABurlington">Burlington</label>
                </div>
            </div>    
        </div>
        

        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MACambridge" value="MA - Cambridge" [(ngModel)]="location">
                    <label class="form-check-label" for="MACambridge">Cambridge</label>
                </div>
            </div>    
        </div>
        
        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MACanton" value="MA - Canton" [(ngModel)]="location">
                    <label class="form-check-label" for="MACanton">Canton</label>
                </div>
            </div>    
        </div>

        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MACharlestown" value="MA - Charlestown" [(ngModel)]="location">
                    <label class="form-check-label" for="MACharlestown">Charlestown</label>
                </div>
            </div>    
        </div>


        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MAConcord" value="MA - Concord" [(ngModel)]="location">
                    <label class="form-check-label" for="MAConcord">Concord</label>
                </div>
            </div>    
        </div>

        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MADedham" value="MA - Dedham" [(ngModel)]="location">
                    <label class="form-check-label" for="MADedham">Dedham</label>
                </div>
            </div>    
        </div>
        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MAFramingham" value="MA - Framingham" [(ngModel)]="location">
                    <label class="form-check-label" for="MAFramingham">Framingham</label>
                </div>
            </div>    
        </div> 
        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MAHingham" value="MA - Hingham" [(ngModel)]="location">
                    <label class="form-check-label" for="MAHingham">Hingham</label>
                </div>
            </div>    
        </div>
        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MAMarshfield" value="MA - Marshfield" [(ngModel)]="location">
                    <label class="form-check-label" for="MAMarshfield">Marshfield</label>
                </div>
            </div>    
        </div>
        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MAMarlborough" value="MA - Marlborough" [(ngModel)]="location">
                    <label class="form-check-label" for="MAMarlborough">Marlborough</label>
                </div>
            </div>    
        </div> 
        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MAMiddleton" value="MA - Middleton" [(ngModel)]="location">
                    <label class="form-check-label" for="MAMiddleton">Middleton</label>
                </div>
            </div>    
        </div>

        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MAMilton" value="MA - Milton" [(ngModel)]="location">
                    <label class="form-check-label" for="MAMilton">Milton</label>
                </div>
            </div>    
        </div>        

        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MANewton" value="MA - Newton" [(ngModel)]="location">
                    <label class="form-check-label" for="MANewton">Newton</label>
                </div>
            </div>    
        </div>
        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MANorthborough" value="MA - Northborough" [(ngModel)]="location">
                    <label class="form-check-label" for="MANorthborough">Northborough</label>
                </div>
            </div>    
        </div>

        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MAPeabody" value="MA - Peabody" [(ngModel)]="location">
                    <label class="form-check-label" for="MAPeabody">Peabody</label>
                </div>
            </div>    
        </div>

        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MAQuincy" value="MA - Quincy" [(ngModel)]="location">
                    <label class="form-check-label" for="MAQuincy">Quincy</label>
                </div>
            </div>    
        </div>        

        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MASomerville" value="MA - Somerville" [(ngModel)]="location">
                    <label class="form-check-label" for="MASomerville">Somerville</label>
                </div>
            </div>    
        </div>        


        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MAWakefield" value="MA - Wakefield" [(ngModel)]="location">
                    <label class="form-check-label" for="MAWakefield">Wakefield</label>
                </div>
            </div>    
        </div>

        <div class="col-md-3">
            <div class="custom-control custom-radio custom-control-inline">
                <div class="form-check mb-0">
                    <input class="form-check-input" type="radio" name="location" ngModel id="MAWalthan" value="MA - Waltham" [(ngModel)]="location">
                    <label class="form-check-label" for="MAWalthan">Waltham</label>
                </div>
            </div>    
        </div>

    </div>
    <button class="w-100 btn btn-primary" style="margin-top: 20px;" type="submit" [disabled]="!location" (click)="updateLocation()">NEXT</button>
    <!--end row-->
  </form>
  <!--end form-->
</div>