import { Component, OnChanges, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ReferralService } from '../../../../services/referral.service';
import { CookieService } from 'ngx-cookie-service';

import { RefContact } from '../../../../objectmodels/ref-contact';
import { Referral } from '../../../../objectmodels/referral';

@Component({
  selector: 'app-referral-location',
  templateUrl: './referral-location.component.html',
  styleUrls: ['./referral-location.component.css']
})
export class ReferralLocationComponent implements OnInit {

  formValid:boolean=false;
  referral:Referral=null;
  location:string;
  
  @Input() refID : number;
  @Input() refTOKEN : string;

  @Output() locationSelected = new EventEmitter<boolean>();

  constructor(
    public referralService: ReferralService,
    private cookieService: CookieService
  ) {
    const currentRef = new Referral(
      this.refID,
      this.refTOKEN,
      ""
    );
    this.referral = currentRef;
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes.refID) {
      const currentRef = new Referral(
        this.refID,
        this.refTOKEN,
        ""
      );
      this.referral = currentRef;
    }

    if (changes.refTOKEN) {
      const currentRef = new Referral(
        this.refID,
        this.refTOKEN,
        ""
      );
      this.referral = currentRef;
    }
  }

  ngOnInit(): void {
    // console.log("HELLO");
  }

  updateLocation() {
    // alert(JSON.stringify(this.referral));
    this.referralService.updateReferralLocation(this.referral, this.location)
    .subscribe(results => {
      if (results) {
        this.locationSelected.emit(true);
        // console.log(results);
      } else {
        this.locationSelected.emit(false);
        // console.log(results);
      }
    });
  }  

}
