import { Component, OnChanges, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ReferralService } from '../../../../services/referral.service';
import { CemeteryService } from '../../../../services/cemetery.service';
import { CookieService } from 'ngx-cookie-service';
import { MessageStatus } from'../../../../objectmodels/messageStatus';
import { Cemetery } from '../../../../objectmodels/cemetery';
import { Referral } from '../../../../objectmodels/referral';

import * as data from '../../../../../assets/states.json';

@Component({
  selector: 'app-referral-cemetery',
  templateUrl: './referral-cemetery.component.html',
  styleUrls: ['./referral-cemetery.component.css']
})
export class ReferralCemeteryComponent implements OnInit {

  searchForTerm:string=null;
  searching:boolean=null;
  referral:Referral=null;
  cemeteries: Cemetery[];
  noresults:boolean=null;
  addCemetery:boolean=false;
  selectedCemetery:Cemetery=null;
  states: any = (data as any).default;

  @Input() refID : number;
  @Input() refTOKEN : string;  

  @Output() cemeteryAdded = new EventEmitter<boolean>();

  constructor(
    public cemeteryService: CemeteryService,
    public referralService: ReferralService,
    private cookieService: CookieService
  ) {
    const currentRef = new Referral(
      this.refID,
      this.refTOKEN,
      ""
    );
    this.referral = currentRef;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.refID) {
      const currentRef = new Referral(
        this.refID,
        this.refTOKEN,
        ""
      );
      this.referral = currentRef;
      // console.dir(this.referral);
    }

    if (changes.refTOKEN) {
      const currentRef = new Referral(
        this.refID,
        this.refTOKEN,
        ""
      );
      this.referral = currentRef;
      // console.dir(this.referral);
    }
  }   

  initialSearch() {
    this.searching=true;
    let searchVal = this.searchForTerm;
    this.searchForTerm="";
    this.cemeteryService.searchCemeteries(searchVal)
    .subscribe(results => {
      if (results) {
        this.cemeteries = results;
        if (this.cemeteries.length <= 0) {
          this.noresults=true;
        } else {
          this.noresults=null;
        }
      } else {
        // some kind of error here
        this.noresults=true;
      }
      this.searching=false;
    });
  }
  addCem(){
    if (this.addCemetery == false) {
      this.addCemetery = true;
    } else {
      this.addCemetery = false;
    }
  }

  selectCem(cem: Cemetery) {
    this.selectedCemetery = cem;
    this.referralService.updateReferralCemetery(this.referral, cem)
    .subscribe(results => {
      if (results) {
        this.cemeteryAdded.emit(true);
      } else {
        this.cemeteryAdded.emit(false);
      }
    });
  }
  updateCemetery() {
    let cemeteryName = document.forms["cemeteryForm"]["cemeteryName"].value;
    let cemeteryPhone = document.forms["cemeteryForm"]["cemeteryPhone"].value;
    let cemeteryEmail = document.forms["cemeteryForm"]["cemeteryEmail"].value;
    let cemeteryAddressOne = document.forms["cemeteryForm"]["cemeteryAddressOne"].value;
    let cemeteryCity = document.forms["cemeteryForm"]["cemeteryCity"].value;
    let cemeteryState = document.forms["cemeteryForm"]["cemeteryState"].value;
    let cemeteryZip = document.forms["cemeteryForm"]["cemeteryZip"].value;    
    const cemetery = new Cemetery(
      0,
      cemeteryName,
      cemeteryAddressOne,
      '',
      cemeteryCity,
      cemeteryState,
      cemeteryZip,
      'US',
      cemeteryPhone,
      cemeteryEmail,
      '',
      '',
      '',
      '',
      '',
      0,
      '',
      '',
      0,
      0,
      false,
      false,
      false,
      '',
      0
    );
    this.referralService.updateReferralCemetery(this.referral, cemetery)
    .subscribe(results => {
      if (results) {
        this.cemeteryAdded.emit(true);
      } else {
        this.cemeteryAdded.emit(false);
      }
    });
  }   

}
