import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-gallery-monument-onsite-engraving',
  templateUrl: './gallery-monument-onsite-engraving.component.html',
  styleUrls: ['./gallery-monument-onsite-engraving.component.css']
})
export class GalleryMonumentOnsiteEngravingComponent implements OnInit {

  navClass = 'nav-light';
    // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;
  footerVariant = 'footer-four';
  
  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

}
