<form [formGroup]="form">

	<div class="row g-3" style="background-color: #F3F3F3;margin-top: 10px;">
		<div class="col-lg-12" style="padding: 0px 16px;">
	    	<p>Please upload up to 3 photos of any monument ideas or images of an existing monument that your customer would like to add an engraving to.</p>
	    </div>
	    <div class="col-lg-4" style="padding:0px 16px 16px 16px;">
			<ng-container *ngIf="progressFileOne <= 0 && !uploadOneComplete">
		        <label for="file-upload-one" class="form-label">Image One :</label>
		        <input class="form-control" type="file" id="file-upload-one" (change)="uploadFileOne($event)"/>
			</ng-container>
			<ng-container *ngIf="progressFileOne > 0 && !uploadOneComplete">
				<strong>{{uploadMssgOne}}</strong><br>
				<div class="progress">
					<div class="progress-bar" role="progressbar" attr.aria-valuenow="{{ progressFileOne }}" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{ width: progressFileOne + '%' }">{{ progressFileOne }} % </div>
				</div>
			</ng-container>
			<ng-container *ngIf="uploadOneMSSGStatus">
				<!-- RESULTS = {{uploadOneMSSGStatus.MSSG}}<BR> -->
				<img [src]="uploadOneMSSGStatus.URL" class="img-fluid" alt="Responsive image">
			</ng-container>
	    </div>
	    <div class="col-lg-4" style="padding:0px 16px 16px 16px;">
			<ng-container *ngIf="progressFileTwo <= 0 && !uploadTwoComplete">
		        <label for="file-upload-two" class="form-label">Image Two :</label>
		        <input class="form-control" type="file" id="file-upload-two" (change)="uploadFileTwo($event)"/>
			</ng-container>
			<ng-container *ngIf="progressFileTwo > 0 && !uploadTwoComplete">
				<strong>{{uploadMssgTwo}}</strong><br>
				<div class="progress">
					<div class="progress-bar" role="progressbar" attr.aria-valuenow="{{ progressFileTwo }}" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{ width: progressFileTwo + '%' }">{{ progressFileTwo }} % </div>
				</div>
			</ng-container>
			<ng-container *ngIf="uploadTwoMSSGStatus">
				<!-- RESULTS = {{uploadTwoMSSGStatus.MSSG}}<BR> -->
				<img [src]="uploadTwoMSSGStatus.URL" class="img-fluid" alt="Responsive image">
			</ng-container>
	    </div>
	    <div class="col-lg-4" style="padding:0px 16px 16px 16px;">
			<ng-container *ngIf="progressFileThree <= 0 && !uploadThreeComplete">
		        <label for="file-upload-three" class="form-label">Image Three :</label>
		        <input class="form-control" type="file" id="file-upload-three" (change)="uploadFileThree($event)"/>
			</ng-container>
			<ng-container *ngIf="progressFileThree > 0 && !uploadThreeComplete">
				<strong>{{uploadMssgThree}}</strong><br>
				<div class="progress">
					<div class="progress-bar" role="progressbar" attr.aria-valuenow="{{ progressFileThree }}" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{ width: progressFileThree + '%' }">{{ progressFileThree }} % </div>
				</div>
			</ng-container>
			<ng-container *ngIf="uploadThreeMSSGStatus">
				<!-- RESULTS = {{uploadThreeMSSGStatus.MSSG}}<BR> -->
				<img [src]="uploadThreeMSSGStatus.URL" class="img-fluid" alt="Responsive image">
			</ng-container>
	    </div>            
	</div>
</form>