import { Component, OnInit } from '@angular/core';
import { NgxMasonryOptions } from 'ngx-masonry';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-gallery-monument-civic-jobs',
  templateUrl: './gallery-monument-civic-jobs.component.html',
  styleUrls: ['./gallery-monument-civic-jobs.component.css']
})
export class GalleryMonumentCivicJobsComponent implements OnInit {

  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;
  footerVariant = 'footer-four';
  
  private _album = [];
  /***
   * Masonry Option
   */
  public myOptions: NgxMasonryOptions = {
    horizontalOrder: true
  };

  /**
   * Portfolio Masonry Five Data
   */
  filterredImages;
  galleryFilter = 'all';
  list = [
  {
    image: 'walker-memorial-render.jpg',
    title: 'Walker Memorial Render',
    text: 'Rendering',
    category: 'all'
  },
  {
    image: 'walker-memorial-installed.jpg',
    title: 'Walker Memorial Installed',
    text: 'Installed',
    category: 'all'
  },
  {
    image: 'general-dynamics-render.jpg',
    title: 'General Dynamics Rendering',
    text: 'Rendering',
    category: 'all'
  },
  {
    image: 'general-dynamics-installed.jpg',
    title: 'General Dyamics Installed',
    text: 'Installed',
    category: 'all'
  },{
    image: 'large-letblack-vet-memorial-002.jpg',
    title: 'Vetrans Memorial',
    text: 'Large Jet Black memorial',
    category: 'all'
  },{
    image: 'large-letblack-vet-memorial-001.jpg',
    title: 'Vetrans Memorial',
    text: 'Large Jet Black memorial',
    category: 'all'
  },
  {
    image: 'large-letblack-vet-memorial-003.jpg',
    title: 'Vetrans Memorial',
    text: 'Large Jet Black memorial',
    category: 'all'
  },
  {
    image: 'large-letblack-vet-memorial-004.jpg',
    title: 'Vetrans Memorial',
    text: 'Large Jet Black memorial',
    category: 'all'
  },
  {
    image: 'large-letblack-vet-memorial-006.jpg',
    title: 'Vetrans Memorial',
    text: 'Large Jet Black memorial',
    category: 'all'
  }
  ];

  constructor(private _lightbox: Lightbox) {}

  ngOnInit(): void {
    this.filterredImages = this.list;
    this.resortImages();
  }

  resortImages() {
    this._album = [];
    for (let i = 0; i <= this.filterredImages.length; i++) {
      const src = 'assets/images/monuments/' + this.filterredImages[i].image;
      const caption = 'Image ' + this.filterredImages[i].title;
      const thumb = 'assets/images/monuments/' + this.filterredImages[i].image;
      const item = {
        src: src,
        caption: caption,
        thumb: thumb
      };
      this._album.push(item);
    }
  }

  activeCategory(category) {
    this.galleryFilter = category;
    if (this.galleryFilter === 'all') {
      this.filterredImages = this.list;
      this.resortImages();
    } else {
      this.filterredImages = this.list.filter(x => x.category === this.galleryFilter);
      this.resortImages();
    }
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._album, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

}
