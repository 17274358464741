<ng-container *ngIf="!isComplete">
<div class="card rounded shadow p-4 border-0">
    <form class="needs-validation" name="finalizeForm" #finalizeForm="ngForm">
        <div class="row g-3">
            <div class="col-md-12">
                <label class="form-label">Cemetery Section and Lot Information</label>
                <div class="form-icon position-relative">
                    <textarea #cemSectionLotInfo="ngModel" name="cemSectionLotInfo" id="cemSectionLotInfo" rows="4" class="form-control" placeholder="Section Lot Info :" ngModel></textarea>
                </div>
            </div>
            <div class="col-md-12">
                <label class="form-label">Order details</label>
                <div class="form-icon position-relative">
                    <textarea #orderDetails="ngModel" name="orderDetails" id="orderDetails" rows="4" class="form-control" placeholder="please describe the product/service your client is interested in :" ngModel></textarea>
                </div>
            </div>
            <div class="col-md-12">
<app-file-uploader [refID]="refID" [refTOKEN]="refTOKEN" [initUpload]="initUpload" (uploadsDone)="finishSubmission()"></app-file-uploader>
            </div>
          <!--end col-->

            <div class="col-md-12">
                <label class="form-label">Please tell us how and when your customer would like to be contacted. Also, feel free to schedule an appointment for them on our website.</label>
                <div class="form-icon position-relative">
                    <textarea #contactNotes="ngModel" name="contactNotes" id="contactNotes" rows="4" class="form-control" placeholder="Contact notes / details here :" ngModel></textarea>
                </div>
            </div>
          <!--end col-->
            <div class="col-md-12">
                <h5>How would you like us to reach out to the customer?</h5>
            </div>
            <div class="col-md-6">
                <div class="custom-control custom-radio custom-control-inline">
                    <div class="form-check mb-0">
                        <input class="form-check-input" type="radio" name="reachouttocustomer" id="reachouttocustomerPHONE" value="PHONE">
                        <label class="form-check-label" for="reachouttocustomerPHONE">Phone</label>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="custom-control custom-radio custom-control-inline">
                    <div class="form-check mb-0">
                        <input class="form-check-input" type="radio" name="reachouttocustomer" id="reachouttocustomerEMAIL" value="EMAIL">
                        <label class="form-check-label" for="reachouttocustomerEMAIL">Email</label>
                    </div>
                </div>
            </div>

          <!--end col-->
        </div>
        <button class="w-100 btn btn-primary" style="margin-top: 20px;" type="submit" (click)="finalizeClicked()">FINALIZE REFERRAL REQUEST</button>
        <!--end row-->
    </form>
    <!--end form-->
</div>
</ng-container>

<ng-container *ngIf="isComplete">
<div class="card rounded shadow p-4 border-0">
    <div class="row g-3">
        <div class="col-md-12">
            <h5>THANK YOU.</h5>
            <p>Your referral has been submitted. A sales rep will be in contact shortly.</p>
        </div>
    </div>
</div>
</ng-container>