<div class="card rounded shadow p-4 border-0">
  <form class="needs-validation" name="myForm" #myForm="ngForm">
    <div class="row g-3">
      <div class="col-sm-6">
        <div *ngIf="refFName.invalid"><label for="refFName" class="form-label">First Name  <span style="color:#7F1517;">*REQUIRED</span></label></div>
        <div *ngIf="refFName.valid"><label for="refFName" class="form-label">First Name  <span style="color:green;">*VALID</span></label></div>
        <input type="text" required #refFName="ngModel" name="refFName" pattern="^[a-zA-Z\s]*$" placeholder="First Name" ngModel class="form-control" minlength="3" />
      </div>

      <div class="col-sm-6">
        <div *ngIf="refLName.invalid"><label for="refLName" class="form-label">Last Name  <span style="color:#7F1517;">*REQUIRED</span></label></div>
        <div *ngIf="refLName.valid"><label for="refLName" class="form-label">Last Name  <span style="color:green;">*VALID</span></label></div>
        <input type="text" required #refLName="ngModel" name="refLName" pattern="^[a-zA-Z\s]*$" placeholder="Last Name" ngModel class="form-control" minlength="3" />
      </div>

      <div class="col-sm-12">
        <div *ngIf="refBusinessName.invalid"><label for="refBusinessName" class="form-label">Funeral Home / Cemetery name  <span style="color:#7F1517;">*REQUIRED</span></label></div>
        <div *ngIf="refBusinessName.valid"><label for="refBusinessName" class="form-label">Funeral Home / Cemetery name  <span style="color:green;">*VALID</span></label></div>
        <input type="text" required #refBusinessName="ngModel" name="refBusinessName" pattern="^[a-zA-Z\s]*$" placeholder="Funeral Home / Cemetery name" ngModel class="form-control" minlength="6" />
      </div>

      <div class="col-sm-6">
        <div *ngIf="refEmail.invalid"><label for="refEmail" class="form-label">Email  <span style="color:#7F1517;">*REQUIRED</span></label></div>
        <div *ngIf="refEmail.valid"><label for="refEmail" class="form-label">Email <span style="color:green;">*VALID</span></label></div>
        <input type="email" required #refEmail="ngModel" name="refEmail" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" placeholder="your email here" ngModel class="form-control" minlength="6" />
      </div>

      <div class="col-sm-6">
        <label for="refPhone" class="form-label">Phone</label>
        <input type="text" #refPhone="ngModel" name="refPhone" placeholder="111-111-1111" ngModel class="form-control" minlength="10" />
      </div>

      <!--end col-->

      <div class="col-12">
        <label for="refAddress" class="form-label">Address</label>
        <input type="text" class="form-control" id="refAddress" name="refAddress" placeholder="1234 Main St">
      </div>
      <!--end col-->

      <div class="col-sm-5">
        <label for="refCity" class="form-label">City</label>
        <input type="text" class="form-control" id="refCity" name="refCity" placeholder="City" value="">
      </div>

      <div class="col-md-4">
        <label for="refState" class="form-label">State</label>
        <select class="form-select form-control" id="refState">
          <option value="">Choose...</option>
          <ng-container *ngIf="states"><option *ngFor="let st of states" value="{{st.abbreviation}}">{{st.name}}</option></ng-container>
        </select>
      </div>

      <div class="col-md-3">
        <label for="refZip" class="form-label">Zip</label>
        <input type="text" #refZip="ngModel" name="refZip" id="refZip" pattern="^[0-9]*$" placeholder="00000" ngModel class="form-control" minlength="5" />
      </div>
      <!--end col-->
    </div>
    <button class="w-100 btn btn-primary" style="margin-top: 20px;" type="submit" (click)="saveReferralContact()" [disabled]="myForm.invalid"><ng-container *ngIf="refID && refTOKEN">SAVE</ng-container><ng-container *ngIf="!refID && !refTOKEN">NEXT</ng-container></button>
    <!--end row-->
  </form>
  <!--end form-->
</div>