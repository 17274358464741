import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { FileUploadService } from "../../../services/file-upload.service";
import { ReferralService } from '../../../services/referral.service';

import { Referral } from '../../../objectmodels/referral';
import { MessageStatus } from '../../../objectmodels/messageStatus';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css']
})

export class FileUploaderComponent {

  form: UntypedFormGroup;
  @Input() refID : number;
  @Input() refTOKEN : string;
  @Output() uploadCancelled = new EventEmitter();
  @Output() uploadComplete = new EventEmitter();

  @Input() initUpload : boolean = null;
  @Output() uploadsDone = new EventEmitter();

  progressFileOne: number = 0;
  progressFileTwo: number = 0;
  progressFileThree: number = 0;
  
  uploadMssgOne: string = 'File one uploading please wait...';
  uploadMssgTwo: string = 'File two uploading please wait...';
  uploadMssgThree: string = 'File three uploading please wait...';

  uploadOneHasFile:boolean=null;
  uploadTwoHasFile:boolean=null;
  uploadThreeHasFile:boolean=null;

  uploadOneComplete:boolean=false;
  uploadTwoComplete:boolean=false;
  uploadThreeComplete:boolean=false;

  uploadOneMSSGStatus:MessageStatus=null;
  uploadTwoMSSGStatus:MessageStatus=null;
  uploadThreeMSSGStatus:MessageStatus=null;

  uploadNewImage:boolean = false;
  uploadCount:number=0;
  
  constructor(
    public fb: UntypedFormBuilder,
    public fileUploadService: FileUploadService
  ){
    this.form = this.fb.group({
      docImageOne: [null],
      docImageTwo: [null],
      docImageThree: [null]
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("SIMPLE CHANGES CALLED");
    console.dir(changes.initUpload);
    console.log(JSON.stringify(changes.initUpload));
    if (changes.initUpload.currentValue == false) {
      console.log("IGNORE SIMPLE CHANGES UNCHAGED");
    } else {
      console.log("INIT UPLOAD WAS FIRED HEREEEEE");
      this.submitUploads();
    }
  }

  onCancelPOOP() {
    this.uploadCancelled.emit();
  }

  uploadFileOne(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if(file) {
      this.uploadOneHasFile=true;
      this.uploadCount++;
    } else {
      this.uploadOneHasFile=false;
      this.uploadCount--;
    }
    this.form.patchValue({
      docImageOne: file
    });
    this.form.get('docImageOne').updateValueAndValidity();
    console.log("this.uploadCount == "+this.uploadCount);
  }
  uploadFileTwo(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if(file) {
      this.uploadTwoHasFile=true;
      this.uploadCount++;
    } else {
      this.uploadTwoHasFile=false;
      this.uploadCount--;
    }
    this.form.patchValue({
      docImageTwo: file
    });
    this.form.get('docImageTwo').updateValueAndValidity();
    console.log("this.uploadCount == "+this.uploadCount);
  }
  uploadFileThree(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if(file) {
      this.uploadThreeHasFile=true;
      this.uploadCount++;      
    } else {
      this.uploadThreeHasFile=false;
      this.uploadCount--;
    }
    this.form.patchValue({
      docImageThree: file
    });
    this.form.get('docImageThree').updateValueAndValidity();
    console.log("this.uploadCount == "+this.uploadCount);
  }

////// UPLOAD TEST
  submitUploads() {
    console.log("UPLOADS SUBMITTED!!!");
    if (!this.uploadOneHasFile && !this.uploadTwoHasFile && !this.uploadThreeHasFile) {
      this.checkUploadStatusRespond();
      return;
    }
    if (this.uploadOneHasFile) {
        this.fileUploadService.uploadReferralItem( this.form.value.docImageOne, this.refID, this.refTOKEN, 1 ).subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              this.uploadMssgOne='File one in queu';
              break;
            case HttpEventType.ResponseHeader:
              this.uploadMssgOne='File one UPLOADED ResponseHeader';
              // this.uploadOneComplete=true;
              break;
            case HttpEventType.UploadProgress:
              this.progressFileOne = Math.round(event.loaded / event.total * 100);
              this.uploadMssgOne=`File one ${this.progressFileOne}%`;
              break;
            case HttpEventType.Response:
              this.uploadMssgOne='File one done uploading.';
              this.uploadOneComplete=true;
              let msgSTS:MessageStatus = <MessageStatus> event.body;
              this.uploadOneMSSGStatus=msgSTS;
              this.uploadCount--;
              this.checkUploadStatusRespond();
          }
        })
    }
    if (this.uploadTwoHasFile) {
        this.fileUploadService.uploadReferralItem( this.form.value.docImageTwo, this.refID, this.refTOKEN, 2 ).subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              this.uploadMssgTwo='File two in queu';
              break;
            case HttpEventType.ResponseHeader:
              this.uploadMssgTwo='File two UPLOADED ResponseHeader';
              // this.uploadTwoComplete=true;
              break;
            case HttpEventType.UploadProgress:
              this.progressFileTwo = Math.round(event.loaded / event.total * 100);
              this.uploadMssgTwo=`File two ${this.progressFileTwo}%`;
              break;
            case HttpEventType.Response:
              this.uploadMssgTwo='File two done uploading.';
              this.uploadTwoComplete=true;
              let msgSTS:MessageStatus = <MessageStatus> event.body;
              this.uploadTwoMSSGStatus=msgSTS;
              this.uploadCount--;
              this.checkUploadStatusRespond();
          }
        })
    }
    if (this.uploadThreeHasFile) {
        this.fileUploadService.uploadReferralItem( this.form.value.docImageThree, this.refID, this.refTOKEN, 3 ).subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              this.uploadMssgThree='File three in queu';
              break;
            case HttpEventType.ResponseHeader:
              this.uploadMssgThree='File three UPLOADED ResponseHeader';
              // this.uploadThreeComplete=true;
              break;
            case HttpEventType.UploadProgress:
              this.progressFileThree = Math.round(event.loaded / event.total * 100);
              this.uploadMssgThree=`File three ${this.progressFileThree}%`;
              break;
            case HttpEventType.Response:
              this.uploadMssgThree='File three done uploading.';
              this.uploadThreeComplete=true;
              let msgSTS:MessageStatus = <MessageStatus> event.body;
              this.uploadThreeMSSGStatus=msgSTS;
              this.uploadCount--;
              this.checkUploadStatusRespond();
          }
        })
    }
  }

  checkUploadStatusRespond() {
    if (this.uploadCount == 0) {
      console.log("UPLOAD COUNT == 0 EMIT DONE");
      this.uploadsDone.emit();
    } else {
      console.log("UPLOAD COUNT == "+this.uploadCount+" WAITING TO FINISH");
    }

  }


}
