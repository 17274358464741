import { Component, OnInit } from '@angular/core';
import { NgxMasonryOptions } from 'ngx-masonry';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-gallery-monument-bronze',
  templateUrl: './gallery-monument-bronze.component.html',
  styleUrls: ['./gallery-monument-bronze.component.css']
})
export class GalleryMonumentBronzeComponent implements OnInit {

  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;
  footerVariant = 'footer-four';
  
  private _album = [];
  /***
   * Masonry Option
   */
  public myOptions: NgxMasonryOptions = {
    horizontalOrder: true
  };

  /**
   * Portfolio Masonry Five Data
   */
  filterredImages;
  galleryFilter = 'all';
  list = [
  {
    image: 'bronze-marker-001.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'novase'
  },
  {
    image: 'bronze-marker-002.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'novase'
  },
  {
    image: 'bronze-marker-003.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'novase'
  },
  {
    image: 'bronze-marker-004.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'novase'
  },
  {
    image: 'bronze-marker-005.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'vase'
  },
  {
    image: 'bronze-marker-006.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'vase'
  },
  {
    image: 'bronze-marker-007.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'vase'
  },
  {
    image: 'bronze-marker-008.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'vase'
  },
  {
    image: 'bronze-marker-009.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'vase'
  },
  {
    image: 'bronze-marker-010.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'vase'
  },
  {
    image: 'bronze-marker-011.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'novase'
  },
  {
    image: 'bronze-marker-012.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'novase'
  },
  {
    image: 'bronze-marker-013.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'vase'
  },
  {
    image: 'bronze-marker-014.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'novase'
  },
  {
    image: 'bronze-marker-015.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'novase'
  },
  {
    image: 'bronze-marker-016.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'novase'
  },
  {
    image: 'bronze-marker-017.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'vase'
  },
  {
    image: 'bronze-marker-018.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'vase'
  },
  {
    image: 'bronze-marker-019.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'novase'
  },
  {
    image: 'bronze-marker-020.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'vase'
  },
  {
    image: 'bronze-marker-021.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'novase'
  },
  {
    image: 'bronze-marker-022.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'novase'
  },
  {
    image: 'bronze-marker-023.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'vase'
  },
  {
    image: 'bronze-marker-024.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'vase'
  },
  {
    image: 'bronze-marker-025.jpg',
    title: 'Bronze Monument',
    text: 'Bronze',
    category: 'novase'
  }
  ];

  constructor(private _lightbox: Lightbox) {}

  ngOnInit(): void {
    this.filterredImages = this.list;
    this.resortImages();
  }

  resortImages() {
    this._album = [];
    for (let i = 0; i <= this.filterredImages.length; i++) {
      const src = 'assets/images/monuments/' + this.filterredImages[i].image;
      const caption = 'Image ' + this.filterredImages[i].title;
      const thumb = 'assets/images/monuments/' + this.filterredImages[i].image;
      const item = {
        src: src,
        caption: caption,
        thumb: thumb
      };
      this._album.push(item);
    }
  }

  activeCategory(category) {
    this.galleryFilter = category;
    if (this.galleryFilter === 'all') {
      this.filterredImages = this.list;
      this.resortImages();
    } else {
      this.filterredImages = this.list.filter(x => x.category === this.galleryFilter);
      this.resortImages();
    }
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._album, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

}
