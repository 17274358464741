export class RefContact {

	constructor(
		public refFName: string,
		public refLName: string,
		public refBusinessName: string,
		public refPhone: string,
		public refEmail: string,
		public refAddress: string,
		public refCity: string,
		public refState: string,
		public refZip: string,
		public isEditing:boolean = false
	) {}

}
