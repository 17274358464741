import { Component, OnInit } from '@angular/core';
import { NgxMasonryOptions } from 'ngx-masonry';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-gallery-monument-upright',
  templateUrl: './gallery-monument-upright.component.html',
  styleUrls: ['./gallery-monument-upright.component.css']
})
export class GalleryMonumentUprightComponent implements OnInit {

  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;
  footerVariant = 'footer-four';
  
  private _album = [];
  /***
   * Masonry Option
   */
  public myOptions: NgxMasonryOptions = {
    horizontalOrder: true
  };

  /**
   * Portfolio Masonry Five Data
   */
  filterredImages;
  galleryFilter = 'all';
  list = [
 {
    image: 'trad-sandblast-religious-030.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-001.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-002.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-003.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-004.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-005.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-006.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-007.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-008.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-009.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-010.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-011.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-012.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-013.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-014.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-015.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-016.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-017.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-018.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-019.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-020.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-021.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-023.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-024.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-025.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-026.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-027.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-028.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-religious-029.jpg',
    title: 'Traditional Sandblast',
    text: 'Religious',
    category: 'Sandblast'
  },{
    image: 'trad-sandblast-porcelain-photo-010.jpg',
    title: 'Traditional Sandblast with porcelain photo',
    text: 'Porcelain Photo',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-porcelain-photo-001.jpg',
    title: 'Traditional Sandblast with porcelain photo',
    text: 'Porcelain Photo',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-porcelain-photo-002.jpg',
    title: 'Traditional Sandblast with porcelain photo',
    text: 'Porcelain Photo',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-porcelain-photo-003.jpg',
    title: 'Traditional Sandblast with porcelain photo',
    text: 'Porcelain Photo',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-porcelain-photo-004.jpg',
    title: 'Traditional Sandblast with porcelain photo',
    text: 'Porcelain Photo',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-porcelain-photo-005.jpg',
    title: 'Traditional Sandblast with porcelain photo',
    text: 'Porcelain Photo',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-porcelain-photo-006.jpg',
    title: 'Traditional Sandblast with porcelain photo',
    text: 'Porcelain Photo',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-porcelain-photo-007.jpg',
    title: 'Traditional Sandblast with porcelain photo',
    text: 'Porcelain Photo',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-porcelain-photo-008.jpg',
    title: 'Traditional Sandblast with porcelain photo',
    text: 'Porcelain Photo',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-porcelain-photo-009.jpg',
    title: 'Traditional Sandblast with porcelain photo',
    text: 'Porcelain Photo',
    category: 'Sandblast'
  },{
    image: 'trad-sandblast-nature-025.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },
{
    image: 'trad-sandblast-nature-001.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },
{
    image: 'trad-sandblast-nature-002.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },
{
    image: 'trad-sandblast-nature-003.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },
{
    image: 'trad-sandblast-nature-004.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },
{
    image: 'trad-sandblast-nature-005.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },
{
    image: 'trad-sandblast-nature-006.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },
{
    image: 'trad-sandblast-nature-007.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },
{
    image: 'trad-sandblast-nature-008.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },
{
    image: 'trad-sandblast-nature-009.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },
{
    image: 'trad-sandblast-nature-010.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },
{
    image: 'trad-sandblast-nature-011.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },
{
    image: 'trad-sandblast-nature-012.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },
{
    image: 'trad-sandblast-nature-013.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },
{
    image: 'trad-sandblast-nature-014.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },
{
    image: 'trad-sandblast-nature-015.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },
{
    image: 'trad-sandblast-nature-016.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },
{
    image: 'trad-sandblast-nature-017.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },
{
    image: 'trad-sandblast-nature-018.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },
{
    image: 'trad-sandblast-nature-019.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },
{
    image: 'trad-sandblast-nature-020.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },
{
    image: 'trad-sandblast-nature-021.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },
{
    image: 'trad-sandblast-nature-022.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },
{
    image: 'trad-sandblast-nature-023.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },
{
    image: 'trad-sandblast-nature-024.jpg',
    title: 'Traditional Sandblast Nature',
    text: 'Sandblast nature',
    category: 'Sandblast'
  },{
    image: 'trad-sandblast-loveandmarriage-019.jpg',
    title: 'Traditional Sandblast',
    text: 'Love and Marriage',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-loveandmarriage-001.jpg',
    title: 'Traditional Sandblast',
    text: 'Love and Marriage',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-loveandmarriage-002.jpg',
    title: 'Traditional Sandblast',
    text: 'Love and Marriage',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-loveandmarriage-003.jpg',
    title: 'Traditional Sandblast',
    text: 'Love and Marriage',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-loveandmarriage-004.jpg',
    title: 'Traditional Sandblast',
    text: 'Love and Marriage',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-loveandmarriage-005.jpg',
    title: 'Traditional Sandblast',
    text: 'Love and Marriage',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-loveandmarriage-006.jpg',
    title: 'Traditional Sandblast',
    text: 'Love and Marriage',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-loveandmarriage-007.jpg',
    title: 'Traditional Sandblast',
    text: 'Love and Marriage',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-loveandmarriage-008.jpg',
    title: 'Traditional Sandblast',
    text: 'Love and Marriage',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-loveandmarriage-009.jpg',
    title: 'Traditional Sandblast',
    text: 'Love and Marriage',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-loveandmarriage-010.jpg',
    title: 'Traditional Sandblast',
    text: 'Love and Marriage',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-loveandmarriage-011.jpg',
    title: 'Traditional Sandblast',
    text: 'Love and Marriage',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-loveandmarriage-012.jpg',
    title: 'Traditional Sandblast',
    text: 'Love and Marriage',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-loveandmarriage-013.jpg',
    title: 'Traditional Sandblast',
    text: 'Love and Marriage',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-loveandmarriage-014.jpg',
    title: 'Traditional Sandblast',
    text: 'Love and Marriage',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-loveandmarriage-015.jpg',
    title: 'Traditional Sandblast',
    text: 'Love and Marriage',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-loveandmarriage-016.jpg',
    title: 'Traditional Sandblast',
    text: 'Love and Marriage',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-loveandmarriage-017.jpg',
    title: 'Traditional Sandblast',
    text: 'Love and Marriage',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-loveandmarriage-018.jpg',
    title: 'Traditional Sandblast',
    text: 'Love and Marriage',
    category: 'Sandblast'
  },{
    image: 'trad-sandblast-herritage-002.jpg',
    title: 'Traditional Sandblast',
    text: 'Herritage',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-herritage-001.jpg',
    title: 'Traditional Sandblast',
    text: 'Herritage',
    category: 'Sandblast'
  }, {
    image: 'trad-sandblast-custom-floral-001.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Scene',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-scene-001.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Scene',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-scene-002.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Scene',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-scene-003.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Scene',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-scene-004.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Scene',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-scene-005.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Scene',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-scene-006.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Scene',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-scene-007.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Scene',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-scene-008.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Scene',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-scene-009.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Scene',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-scene-010.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Scene',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-scene-011.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Scene',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-scene-012.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Scene',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-scene-013.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Scene',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-scene-014.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Scene',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-scene-015.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Scene',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-scene-016.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Scene',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-scene-017.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Scene',
    category: 'Sandblast'
  },{
    image: 'trad-sandblast-custom-emblem-019.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Emblem',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-emblem-001.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Emblem',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-emblem-002.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Emblem',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-emblem-003.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Emblem',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-emblem-004.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Emblem',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-emblem-005.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Emblem',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-emblem-006.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Emblem',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-emblem-007.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Emblem',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-emblem-008.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Emblem',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-emblem-009.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Emblem',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-emblem-010.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Emblem',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-emblem-011.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Emblem',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-emblem-012.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Emblem',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-emblem-013.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Emblem',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-emblem-014.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Emblem',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-emblem-015.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Emblem',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-emblem-016.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Emblem',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-emblem-017.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Emblem',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-custom-emblem-018.jpg',
    title: 'Traditional Sandblast',
    text: 'Custom Emblem',
    category: 'Sandblast'
  },  {
    image: 'trad-sandblast-blank-019.jpg',
    title: 'Traditional Sandblast',
    text: 'Blank',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-blank-001.jpg',
    title: 'Traditional Sandblast',
    text: 'Blank',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-blank-002.jpg',
    title: 'Traditional Sandblast',
    text: 'Blank',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-blank-003.jpg',
    title: 'Traditional Sandblast',
    text: 'Blank',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-blank-004.jpg',
    title: 'Traditional Sandblast',
    text: 'Blank',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-blank-005.jpg',
    title: 'Traditional Sandblast',
    text: 'Blank',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-blank-006.jpg',
    title: 'Traditional Sandblast',
    text: 'Blank',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-blank-007.jpg',
    title: 'Traditional Sandblast',
    text: 'Blank',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-blank-008.jpg',
    title: 'Traditional Sandblast',
    text: 'Blank',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-blank-009.jpg',
    title: 'Traditional Sandblast',
    text: 'Blank',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-blank-010.jpg',
    title: 'Traditional Sandblast',
    text: 'Blank',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-blank-011.jpg',
    title: 'Traditional Sandblast',
    text: 'Blank',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-blank-012.jpg',
    title: 'Traditional Sandblast',
    text: 'Blank',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-blank-013.jpg',
    title: 'Traditional Sandblast',
    text: 'Blank',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-blank-014.jpg',
    title: 'Traditional Sandblast',
    text: 'Blank',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-blank-015.jpg',
    title: 'Traditional Sandblast',
    text: 'Blank',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-blank-016.jpg',
    title: 'Traditional Sandblast',
    text: 'Blank',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-blank-017.jpg',
    title: 'Traditional Sandblast',
    text: 'Blank',
    category: 'Sandblast'
  },
  {
    image: 'trad-sandblast-blank-018.jpg',
    title: 'Traditional Sandblast',
    text: 'Blank',
    category: 'Sandblast'
  },
  {
    image: 'trad-laser-etch-scene-001.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-002.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-003.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-004.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-005.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-006.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-007.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-008.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-009.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-010.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-011.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-012.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-013.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-014.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-015.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-017.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-018.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-019.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-020.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-022.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-023.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-024.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-026.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-027.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-028.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-029.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-scene-030.jpg',
    title: 'Traditional Laser Etch',
    text: 'Scene',
    category: 'Laser Etch'
  },{
    image: 'trad-laser-etch-portrait-008.jpg',
    title: 'Traditional Laser Etch',
    text: 'Portrait',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-portrait-001.jpg',
    title: 'Traditional Laser Etch',
    text: 'Portrait',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-portrait-002.jpg',
    title: 'Traditional Laser Etch',
    text: 'Portrait',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-portrait-004.jpg',
    title: 'Traditional Laser Etch',
    text: 'Portrait',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-portrait-005.jpg',
    title: 'Traditional Laser Etch',
    text: 'Portrait',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-portrait-007.jpg',
    title: 'Traditional Laser Etch',
    text: 'Portrait',
    category: 'Laser Etch'
  },{
    image: 'trad-laser-etch-emblem-008.jpg',
    title: 'Traditional Laser Etch',
    text: 'Emblem',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-emblem-001.jpg',
    title: 'Traditional Laser Etch',
    text: 'Emblem',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-emblem-002.jpg',
    title: 'Traditional Laser Etch',
    text: 'Emblem',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-emblem-003.jpg',
    title: 'Traditional Laser Etch',
    text: 'Emblem',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-emblem-004.jpg',
    title: 'Traditional Laser Etch',
    text: 'Emblem',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-emblem-005.jpg',
    title: 'Traditional Laser Etch',
    text: 'Emblem',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-emblem-006.jpg',
    title: 'Traditional Laser Etch',
    text: 'Emblem',
    category: 'Laser Etch'
  },
  {
    image: 'trad-laser-etch-emblem-007.jpg',
    title: 'Traditional Laser Etch',
    text: 'Emblem',
    category: 'Laser Etch'
  }

  ];

  constructor(private _lightbox: Lightbox) {}

  ngOnInit(): void {
    this.filterredImages = this.list;
    this.resortImages();
  }

  resortImages() {
    this._album = [];
    for (let i = 0; i <= this.filterredImages.length; i++) {
      const src = 'assets/images/monuments/' + this.filterredImages[i].image;
      const caption = 'Image ' + this.filterredImages[i].title;
      const thumb = 'assets/images/monuments/' + this.filterredImages[i].image;
      const item = {
        src: src,
        caption: caption,
        thumb: thumb
      };
      this._album.push(item);
    }
  }

  activeCategory(category) {
    this.galleryFilter = category;
    if (this.galleryFilter === 'all') {
      this.filterredImages = this.list;
      this.resortImages();
    } else {
      this.filterredImages = this.list.filter(x => x.category === this.galleryFilter);
      this.resortImages();
    }
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._album, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

}
