import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function atLeastOneRequired(control1Name: string, control2Name: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const control1 = formGroup.get(control1Name);
    const control2 = formGroup.get(control2Name);
    if ((control1 && control1.value) || (control2 && control2.value)) {
      return null;
    } else {
      return { atLeastOneRequired: true };
    }
  };
}
