import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { atLeastOneRequired } from './custom-validators'; // Ensure this path is correct
import { Booking } from '../../../objectmodels/booking';
import { BookAppointmentsService } from '../../../services/book-appointments.service';

@Component({
  selector: 'app-page-book-appointment-form',
  templateUrl: './page-book-appointment-form.component.html',
  styleUrls: ['./page-book-appointment-form.component.css']
})
export class PageBookAppointmentFormComponent implements OnInit {

  bookingForm: FormGroup;

  navClass = 'nav-light';
  Menuoption = 'center';
  Settingicon = true;
  footerVariant = 'footer-four';
  dateControl = new FormControl();
  timeSlotControl = new FormControl({ value: '', disabled: true });
  events: any[] = [];
  minDate: moment.Moment;
  availableTimeSlots: string[];    

  location: string = '';
  custName: string = '';
  custEmail: string = '';
  custPhone: string = '';
  cemeteryName: string = '';
  selectedService: string = '';
  date: string = ''; 
  timeSlot: string = ''; 
  notes: string = ''; 
  sendingRequest: boolean = false;

  constructor (
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private bookAppointmentsService: BookAppointmentsService, 
    private router: Router,
    private fb: FormBuilder
  ) {
    this.minDate = moment().startOf('day');
    this.availableTimeSlots = [];
  }

  ngOnInit(): void {
    const loc = this.route.snapshot.paramMap.get('loc');
    if (loc !== null) { this.location = loc; }
    
    this.bookingForm = this.fb.group({
      custName: ['', [Validators.required, Validators.pattern('^[a-zA-Z\\s]*$'), Validators.minLength(3)]],
      custEmail: ['', [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      custPhone: ['', [Validators.pattern(/^(\(\d{3}\)|\d{3})([.\-\s]?)\d{3}([.\-\s]?)\d{4}$/)]],
      cemeteryName: ['']  
    }, { validators: atLeastOneRequired('custEmail', 'custPhone') });

    this.generateTimeSlots();
    this.dateControl.valueChanges.subscribe(value => {
      if (value) {
        this.timeSlotControl.enable();
      } else {
        this.timeSlotControl.disable();
      }
    });

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
    this.fetchEvents();
  }

  generateTimeSlots(): void {
    this.availableTimeSlots = ['9:00 AM', '10:00 AM', '11:00 AM', '12:00 PM', '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM'];
  }

  fetchEvents(): void {
    this.bookAppointmentsService.getEvents().subscribe(data => {
      this.events = data;
    });
  }

  selectService(service: string): void {
    this.selectedService = service;
  }

  myFilter = (d: moment.Moment | null): boolean => {
    const day = (d || moment()).day();
    // Prevent Sunday from being selected.
    return day !== 0;
  };

  clearForms(): void {
    this.sendingRequest = false;
    this.bookingForm.reset();
    this.selectedService='';
    this.date='';
    this.timeSlot='';
    this.notes='';
  }



sendBookingRequest(): void {
  this.sendingRequest = true;
  const bookingID = this.generateBookingID();
  const newBooking = new Booking(
    bookingID,
    this.location,
    this.bookingForm.controls.custName.value,
    this.bookingForm.controls.custEmail.value,
    this.bookingForm.controls.custPhone.value,
    this.bookingForm.controls.cemeteryName.value,
    this.selectedService,
    this.date,
    this.timeSlot,
    this.notes
  );

  this.bookAppointmentsService.sendBookingRequest(newBooking)
    .subscribe(
      response => {
        console.log('Booking request sent successfully:', response);
        alert('Thank you\nYour booking request was sent successfully. \nA rep will be in touch shorty to verify your\ntime slot and or schedule one that works.');

        if (this.dateControl.value && this.timeSlotControl.value) {
          const startDateTime = new Date(this.dateControl.value);
          const [time, period] = this.timeSlotControl.value.split(' ');
          let [hours, minutes] = time.split(':').map(Number);

          if (period === 'PM' && hours !== 12) {
            hours += 12;
          } else if (period === 'AM' && hours === 12) {
            hours = 0;
          }

          startDateTime.setHours(hours, minutes);
          const endDateTime = new Date(startDateTime);
          endDateTime.setHours(startDateTime.getHours() + 1);

          let summary = '';
          let colorId = '3'; // Default color ID

          switch (this.selectedService) {
            case "oneonone":
              summary = "In Person Design Meeting";
              colorId = '2'; // Sage
              break;
            case "zoom":
              summary = "Zoom Design Meeting";
              colorId = '5'; // Banana
              break;
            case "engraving":
              summary = "Engraving";
              colorId = '6'; // Tangerine
              break;
            case "other":
              summary = "Other";
              colorId = '7'; // Peacock
              break;
            default:
              summary = "Meeting type not provided";
              colorId = '8'; // Graphite
          }
          const event = {
            summary: summary || 'No summary provided',
            location: this.location || 'No location provided',
            description: `${summary} with ${this.bookingForm.controls.custName.value || 'No name provided'}, Email: ${this.bookingForm.controls.custEmail.value || 'No email provided'}, Phone: ${this.bookingForm.controls.custPhone.value || 'No phone provided'}, Cemetery: ${this.bookingForm.controls.cemeteryName.value || 'No cemetery name provided'}`,
            start: {
              dateTime: startDateTime.toISOString(),
              timeZone: 'America/New_York',
            },
            end: {
              dateTime: endDateTime.toISOString(),
              timeZone: 'America/New_York',
            },
            status: 'tentative',
            colorId: colorId
          };
          console.log('Adding event:', event); // Log event data for debugging
          this.bookAppointmentsService.addEvent(event).subscribe(
            eventResponse => {
              console.log('Event added:', eventResponse);
            },
            eventError => {
              console.error('Error adding event:', eventError);
            }
          );
          this.clearForms();
          this.router.navigate(['/page-book-appointment']);
        } else {
          console.warn('Please select a date and a time slot.');
          this.sendingRequest = false;
        }
      },
      error => {
        console.error('Error sending booking request:', error);
        alert('Error sending booking request. Please try again. If the problem persists, please contact us.');
        this.sendingRequest = false;
      }
    );
}


  private generateBookingID(): string {
    return 'BOOK' + Math.floor(Math.random() * 1000000).toString();
  }
}
