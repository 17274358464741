<!-- Start -->
<section class="bg-half-170 d-table bg-primary w-100" id="home"
    style="background-image: url('assets/images/referral.jpg');">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col">
                <div class="pages-heading title-heading text-center">
                    <h4 class="display-6 text-white title-dark mb-4 fw-bold">Funeral Home / Cemetery Referral Form</h4>
                    <p class="text-white-50 mx-auto">Please complete the monument/engraving referral form, including as much information as possible regarding the client's needs, as well as their preferences for when and how they would like to be contacted. Thank you! We value your partnership.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->
<!-- End -->
<section class="section paddSoft">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <ul class="nav nav-pills nav-justified flex-column flex-sm-row rounded" id="pills-tab" role="tablist">
                    <li class="nav-item">
                        <button class="nav-link rounded active" id="pills-your-info-tab" data-bs-toggle="pill" data-bs-target="#pills-your-info" type="button" role="tab" aria-controls="home" aria-selected="true">Your Info <ng-container *ngIf="cookieRefID && cookieRefToken"><i-feather name="check-circle" class="fea icon-sm icons"></i-feather></ng-container></button>
                    </li><!--end nav item-->
                    <li class="nav-item">
                        <button class="nav-link rounded" id="pills-location-tab" data-bs-toggle="pill" data-bs-target="#pills-location" type="button" role="tab" aria-controls="home" aria-selected="true" [disabled]="!cookieRefID && !cookieRefToken">Location <ng-container *ngIf="cookieRefID && cookieRefToken && locationCreated"><i-feather name="check-circle" class="fea icon-sm icons"></i-feather></ng-container></button>
                    </li><!--end nav item-->
                    <li class="nav-item">
                        <button class="nav-link rounded" id="pills-customer-info-tab" data-bs-toggle="pill" data-bs-target="#pills-customer-info" type="button" role="tab" aria-controls="home" aria-selected="true" [disabled]="!cookieRefID && !cookieRefToken && !locationCreated">Customer Info <ng-container *ngIf="cookieRefID && cookieRefToken && customerCreated"><i-feather name="check-circle" class="fea icon-sm icons"></i-feather></ng-container></button>                        
                    </li><!--end nav item-->
                    <li class="nav-item">
                        <button class="nav-link rounded" id="pills-cemetery-tab" data-bs-toggle="pill" data-bs-target="#pills-cemetery" type="button" role="tab" aria-controls="home" aria-selected="true" [disabled]="!cookieRefID && !cookieRefToken && !customerCreated">Cemetery Info <ng-container *ngIf="cookieRefID && cookieRefToken && cemAdded"><i-feather name="check-circle" class="fea icon-sm icons"></i-feather></ng-container></button>                         
                    </li><!--end nav item-->
                    <li class="nav-item">
                        <button class="nav-link rounded" id="pills-finish-tab" data-bs-toggle="pill" data-bs-target="#pills-finish" type="button" role="tab" aria-controls="home" aria-selected="true" [disabled]="!cookieRefID && !cookieRefToken && !cemAdded">Finish</button>                         
                    </li><!--end nav item-->
                </ul><!--end nav pills-->
            </div><!--end col-->
        </div><!--end row-->

        <div class="row pt-3">
            <div class="col-12">
                <div class="tab-content" id="pills-tabContent">
<!-- START YOUR INFO FORM -->
                    <div class="tab-pane fade show active" id="pills-your-info" role="tabpanel" aria-labelledby="pills-your-info-tab">
                        <app-referral-contact (referralCreated)="ref($event)" [refID]="cookieRefID" [refTOKEN]="cookieRefToken" ></app-referral-contact>
                    </div>
<!-- START LOCATION FORM -->
                    <div class="tab-pane fade" id="pills-location" role="tabpanel" aria-labelledby="pills-location-tab">
                        <app-referral-location (locationSelected)="loc($event)" [refID]="cookieRefID" [refTOKEN]="cookieRefToken"></app-referral-location>
                    </div>
<!-- START CUSTOMER INFO FORM -->
                    <div class="tab-pane fade" id="pills-customer-info" role="tabpanel" aria-labelledby="pills-customer-info-tab">
                        <app-referral-customer (customerCreated)="cust($event)" [refID]="cookieRefID" [refTOKEN]="cookieRefToken"></app-referral-customer>
                    </div>
<!-- START CEMETERY INFO FORM -->
                    <div class="tab-pane fade" id="pills-cemetery" role="tabpanel" aria-labelledby="pills-cemetery-tab">
                        <app-referral-cemetery (cemeteryAdded)="cem($event)" [refID]="cookieRefID" [refTOKEN]="cookieRefToken"></app-referral-cemetery>
                    </div>
<!-- START FINALIZE FORM -->
                    <div class="tab-pane fade" id="pills-finish" role="tabpanel" aria-labelledby="pills-finish-tab">
                        <app-referral-finalize (referralFinalized)="final($event)" [refID]="cookieRefID" [refTOKEN]="cookieRefToken"></app-referral-finalize>
                    </div><!-- END FINALIZE FORM -->
                </div><!--end tab content-->
            </div><!--end col-->
        </div><!--end row-->
    </div>
</section> 
<!-- End -->