export class Referraldetails {

	constructor(
		public cemSectionLotInfo: string,
		public orderDetails: string,
		public contactNotes: string,
		public reachouttocustomer: string
	) {}

}
