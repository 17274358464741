import { Component, OnInit } from '@angular/core';
import { CemeteryService } from '../../../services/cemetery.service';
import { MessageStatus } from'../../../objectmodels/messageStatus';
import { Cemetery } from '../../../objectmodels/cemetery';
import { Referral } from '../../../objectmodels/referral';

import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-referral-in-home',
  templateUrl: './referral-in-home.component.html',
  styleUrls: ['./referral-in-home.component.css']
})
export class ReferralInHomeComponent implements OnInit {

  navClass = 'nav-light';
  Menuoption = 'center';
  Settingicon = true;
  footerVariant = 'footer-four';

  searchForTerm:string=null;
  searching:boolean=null;
  cemeteries: Cemetery[];
  noresults:boolean=null;
  addCemetery:boolean=false;
  locationCreated:boolean=false;
  customerCreated:boolean=false;
  cemAdded:boolean=false;
  finalized:boolean=false;

  referral:Referral=null;
  cookieRefID:string;
  cookieRefToken:string;

  constructor(
    private router: Router,
    public cemeteryService: CemeteryService,
    private cookieService: CookieService
  ) {
    this.cookieRefID = this.cookieService.get('refID');
    this.cookieRefToken = this.cookieService.get('refTOKEN'); 
  }

  ngOnInit(): void {
  
  }

  ref(referral: Referral) {
    this.referral = referral;
    const myDate: Date = new Date();
          myDate.setHours( myDate.getHours() + 1);
    this.cookieService.set('refID', String(this.referral.id), { expires: myDate, sameSite: 'Lax' });
    this.cookieService.set('refTOKEN', this.referral.token, { expires: myDate, sameSite: 'Lax' });
    this.cookieRefID = this.cookieService.get('refID');
    this.cookieRefToken = this.cookieService.get('refTOKEN');
    // alert("PARENT GOT REFERRAL "+this.referral);
    this.changeActiveTab('pills-location');
  }

  loc(res:boolean) {
    this.locationCreated=true;
    this.changeActiveTab('pills-customer-info');
  }
  cust(res:boolean) {
    this.customerCreated=true;
    this.changeActiveTab('pills-cemetery');
  }
  cem(res:boolean) {
    this.cemAdded=true;
    this.changeActiveTab('pills-finish');
  }
  final(res:boolean) {
    // console.log("FINALIZE CALLED res == "+res);
    this.finalized=true;
    this.searchForTerm=null;
    this.searching=null;
    this.noresults=null;
    this.addCemetery=false;
    this.locationCreated=false;
    this.customerCreated=false;
    this.cemAdded=false;
    this.referral=null;
    this.cookieRefID=null;
    this.cookieRefToken=null;
    this.cookieService.deleteAll();
    alert("Thank you! \n\nYour referral has been received. A Richardson customer service representative will be in touch with you shortly. \n\nWe appreciate the referral and look forward to working with you more in the future.");
    window.location.reload();
  }


  changeActiveTab(tabId) {
    // Remove the 'active' class from the currently active tab and tab pane
    const tabs = document.querySelectorAll('.nav-link');
    tabs.forEach(tab => {
      tab.classList.remove('active');
    });

    const tabPanes = document.querySelectorAll('.tab-pane');
    tabPanes.forEach(pane => {
      pane.classList.remove('show', 'active');
    });

    // Add the 'active' class to the desired tab and tab pane
    const targetTab = document.getElementById(tabId + '-tab');
    targetTab.classList.add('active');

    const targetPane = document.getElementById(tabId);
    targetPane.classList.add('show', 'active');
  }



}
