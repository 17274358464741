<!-- Start -->
<section class="section">
  <div class="container">



    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="features-absolute">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-12">
              <div (click)="filterLocations('me')" class="card features feature-clean feature-primary explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center" style="cursor: pointer;">
                <div class="card-body p-0 content">
                  <h5 class="mt-4"><a href="javascript:void(0)" class="title text-dark">MAINE</a></h5>
                  <p class="text-muted">3 - Locations</p>

                  <a href="javascript:void(0)" class="text-primary">VIEW LOCATIONS<i
                      class="uil uil-angle-right-b align-middle"></i></a>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 mt-md-0 pt-2 pt-md-0">
              <div (click)="filterLocations('ma')" class="card features feature-clean feature-primary explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center" style="cursor: pointer;">
                <div class="card-body p-0 content">
                  <h5 class="mt-4"><a href="javascript:void(0)" class="title text-dark">MASSACHUSETTS</a>
                  </h5>
                  <p class="text-muted">20 - Locations</p>

                  <a href="javascript:void(0)" class="text-primary">VIEW LOCATIONS<i
                      class="uil uil-angle-right-b align-middle"></i></a>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
              <div (click)="filterLocations('nh')" class="card features feature-clean feature-primary explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center" style="cursor: pointer;">
                <div class="card-body p-0 content">
                  <h5 class="mt-4"><a href="javascript:void(0)" class="title text-dark">NEW HAMPSHIRE</a></h5>
                  <p class="text-muted">6 - Locations</p>

                  <a href="javascript:void(0)" class="text-primary">VIEW LOCATIONS<i
                      class="uil uil-angle-right-b align-middle"></i></a>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-12 col-md-12 col-12" style="margin-top:40px;cursor: pointer;" (click)="bookAppointment('zoom')">
              <div class="card features feature-clean feature-primary explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center" style="background-color: #7F1517;">
                <div class="card-body p-0 content">
                  <h5 class="mt-4"><a href="javascript:void(0)" class="title text-light">CANT MAKE IT IN PERSON. NO PROBLEM</a></h5>
                  <p class="text-light">Schedule a virtual design meeting via Zoom, <img src="assets/images/Zoom-Logo.png"> with one of our reps by clicking below.</p>
                  <a href="javascript:void(0)" class="text-light">SCHEDULE HERE<i class="uil uil-angle-right-b align-middle"></i></a>
                </div>
              </div>
            </div>
            <!--end col-->            

          </div>
        </div>
      </div>
    </div>


    <div class="row projects-wrapper" #targetDiv style="margin-top: 40px;">
      <!-- MAINE -->
      <ng-container *ngIf="showMaine">
            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 designing">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MAINE - <strong>Auburn</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-maine-aub.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                    <h5>155 Center Street, unit 202, Auburn me, 04210</h5>
                    <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-auburn-me">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div>
            <!--end col-->  
            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 designing">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MAINE - <strong>Biddeford</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-maine-bidd.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                    <h5>321 Elm St. Biddeford, ME 04005</h5>
                    <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-biddeford-me">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div>
            <!--end col-->  
            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MAINE - <strong>South Portland</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-maine-sopo.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                    <h5>331 Lincoln Street South Portland, ME 04106</h5>
                    <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-south-portland-me">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div>
            <!--end col-->
      </ng-container>

      <!-- MASS -->
      <ng-container *ngIf="showMass">

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MASSACHUSETTS - <strong>Andover</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-ma-andover.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                      <h5>300 Brickstone Square Suite 201, Andover, MA 01810</h5>
                      <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-andover-ma">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MASSACHUSETTS - <strong>Burlington</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-ma-burlington.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                      <h5>1500 District Avenue 1st and 2nd Floor, Burlington, MA 01803</h5>
                      <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-burlington-ma">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MASSACHUSETTS - <strong>Cambridge</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-ma-cambridge.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                      <h5>Alewife Station, 125 Cambridgepark Dr Suite 301, Cambridge, MA 02140</h5>
                      <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-cambridge-ma">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div>



            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MASSACHUSETTS - <strong>Canton</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-ma-canton.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                      <h5>437 Turnpike St, Canton, MA 02021</h5>
                      <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-canton-ma">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div>




            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MASSACHUSETTS - <strong>Charlestown</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-ma-charlestown.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                      <h5>The Schrafft's Center Power House, Main St Suite 200, Charlestown, MA 02129</h5>
                      <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-charlestown-ma">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MASSACHUSETTS - <strong>Concord</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-ma-concord.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                      <h5>300 Baker Ave #300a, Concord, MA 01742</h5>
                      <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-concord-ma">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div> 

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MASSACHUSETTS - <strong>Dedham</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-ma-dedham.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                      <h5>3 Allied Dr #303, Dedham, MA 02026</h5>
                      <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-dedham-ma">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div> 
            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MASSACHUSETTS - <strong>Framingham</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-ma-framingham.jpg" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                      <h5>945 Concord Street Framingham, MA 01701</h5>
                      <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-framingham-ma">GET IN TOUCH</button>
                      <!-- <button type="submit" id="submit" name="send" class="btn btn-primary" (click)="bookAppointment('ma-waltham')">BOOK HERE</button> -->
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MASSACHUSETTS - <strong>Hingham</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-ma-hingham.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                      <h5>99 Derby St Suite 200, Hingham, MA 02043</h5>
                      <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-hingham-ma">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div> 
            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MASSACHUSETTS - <strong>Marshfield</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-ma-marshfield.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                      <h5>1025 Plain St #1, Marshfield, MA 02050</h5>
                      <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-marshfield-ma">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div>             

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MASSACHUSETTS - <strong>Marlborough</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-ma-marlborough.jpg" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                      <h5>225 Cedar Hill Street Suite 200 Marlborough, MA 01752</h5>
                      <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-marlborough-ma">GET IN TOUCH</button>
                      <!-- <button type="submit" id="submit" name="send" class="btn btn-primary" (click)="bookAppointment('ma-waltham')">BOOK HERE</button> -->
                  </div>
                </div>
              </div>
            </div>


            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MASSACHUSETTS - <strong>Middleton</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-ma-middleton.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                      <h5>35 Village Rd Suite 100, Middleton, MA 01949</h5>
                      <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-middleton-ma">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div>


            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MASSACHUSETTS - <strong>Milton</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-ma-milton.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                      <h5>2 Granite Ave Suite 260, Milton, MA 02186</h5>
                      <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-milton-ma">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div>            

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MASSACHUSETTS - <strong>Newton</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-ma-newton.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                      <h5>Riverside Center, 275 Grove St #2400, Newton, MA 02466</h5>
                      <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-newton-ma">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MASSACHUSETTS - <strong>Northborough</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-ma-northborough.jpg" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                      <h5>44 Bearfoot Road Suite 200 Northborough, MA 01532</h5>
                      <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-northborough-ma">GET IN TOUCH</button>
                      <!-- <button type="submit" id="submit" name="send" class="btn btn-primary" (click)="bookAppointment('ma-waltham')">BOOK HERE</button> -->
                  </div>
                </div>
              </div>
            </div>


            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MASSACHUSETTS - <strong>Peabody</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-ma-peabody.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                      <h5>9 Centennial Dr 2nd Floor, Peabody, MA 01960</h5>
                      <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-peabody-ma">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MASSACHUSETTS - <strong>Quincy</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-ma-quincy.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                      <h5>859 Willard St Suite 400, Quincy, MA 02169</h5>
                      <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-quincy-ma">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div>            

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MASSACHUSETTS - <strong>Somerville</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-ma-somerville.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                      <h5>240 Elm Street, 2nd Floor, Somerville, Massachusetts, 02144</h5>
                      <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-somerville-ma">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MASSACHUSETTS - <strong>Wakefield</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-ma-wafefield.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                      <h5>301 Edgewater Pl Suite 100, Wakefield, MA 01880</h5>
                      <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-wakefield-ma">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div> 

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">MASSACHUSETTS - <strong>Waltham</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-ma-waltham.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                      <h5>303 Wyman St #300, Waltham, MA 02451</h5>
                      <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-waltham-ma">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div>

      </ng-container>

      <!-- NEW HAMPSHIRE -->
      <ng-container *ngIf="showNH">
            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 photography">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">NEW HAMPSHIRE - <strong>Rochester</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-nh-rochester.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                    <h5>115 Highland St Rochester, NH 03868</h5>
                    <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-rochester-nh">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 development">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">NEW HAMPSHIRE - <strong>Portsmouth - Seacoast</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-nh-portsmouth.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                    <h5>2800 Heritage Ave, Unit 17 Portsmouth, NH 03801</h5>
                    <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-portsmouth-nh">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">NEW HAMPSHIRE - <strong>Bedford</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-nh-bedford.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                    <h5>15 Constitution Dr #1A, Bedford, NH 03110</h5>
                    <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-manchester-nh">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">NEW HAMPSHIRE - <strong>Nashua</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-nh-nashua.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                    <h5>1 Tara Blvd #200, Nashua, NH 03062</h5>
                    <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-nashua-nh">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">NEW HAMPSHIRE - <strong>Concord</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-nh-concord.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                    <h5>20 Foundry St 2nd Floor, Concord, NH 03301</h5>
                    <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-concord-nh">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div> 

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding">
              <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <div class="card-body p-0">
                  <h6 class="mb-0">NEW HAMPSHIRE - <strong>Keene</strong></h6>
                  <a class="mfp-image d-inline-block" href="javascript: void(0);" title="">
                    <img src="assets/images/maps/richardson-nh-keene.png" class="img-fluid" alt="work-image">
                  </a>
                  <div class="content bg-white p-3">
                    <h5>25 Roxbury St, Keene, NH 03431</h5>
                    <button type="submit" id="submit" name="send" class="btn btn-primary" routerLink="/contact-keene-nh">GET IN TOUCH</button>
                  </div>
                </div>
              </div>
            </div> 

      </ng-container>











      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->

