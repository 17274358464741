import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { RefContact } from'../objectmodels/ref-contact';
import { RefCustomer } from'../objectmodels/refCustomer';
import { Referral } from'../objectmodels/referral';
import { Cemetery } from'../objectmodels/cemetery';
import { Referraldetails } from'../objectmodels/referraldetails';

@Injectable({
  providedIn: 'root'
})
export class ReferralService {
  
  private createNewReferralForURL = 'https://www.stone-simple.com/WSLIVE_RICHARDSON/referrals/create_new_referral.php';
  private updateReferralContactForURL = 'https://www.stone-simple.com/WSLIVE_RICHARDSON/referrals/update_contact_referral.php';
  private updateLocationForReferralForURL = 'https://www.stone-simple.com/WSLIVE_RICHARDSON/referrals/update_location_referral.php';
  private updateCustomerForReferralForURL = 'https://www.stone-simple.com/WSLIVE_RICHARDSON/referrals/update_customer_referral.php';
  private updateCemeteryForReferralForURL = 'https://www.stone-simple.com/WSLIVE_RICHARDSON/referrals/update_cemetery_referral.php';
  private updateReferralDetailsForURL = 'https://www.stone-simple.com/WSLIVE_RICHARDSON/referrals/update_referral_details.php';
  private finalizeAndSendReferralURL = 'https://www.stone-simple.com/WSLIVE_RICHARDSON/referrals/finalize_referral.php';
  
  constructor(
    private http: HttpClient
  ) { }
  
  createNewReferral(refContact:RefContact): Observable<Referral> {
    return this.http.post<Referral>(`${this.createNewReferralForURL}`, { ref: refContact })
        .pipe(map((res) => {
          if(res) {
            return res;
          } else {
            return null;
          }
        }));
  }
  updateReferralContact(ref:Referral, contact:RefContact): Observable<number> {
    return this.http.post<number>(`${this.updateReferralContactForURL}`, { ref: ref, contact: contact })
        .pipe(map((res) => {
          if(res) {
            return res;
          } else {
            return null;
          }
        }));
  } 
  updateReferralLocation(ref:Referral, location:string): Observable<number> {
    return this.http.post<number>(`${this.updateLocationForReferralForURL}`, { ref: ref, loc: location })
        .pipe(map((res) => {
          if(res) {
            return res;
          } else {
            return null;
          }
        }));
  } 
  updateReferralCustomer(ref:Referral, cust:RefCustomer): Observable<number> {
    return this.http.post<number>(`${this.updateCustomerForReferralForURL}`, { ref: ref, customer: cust })
        .pipe(map((res) => {
          if(res) {
            return res;
          } else {
            return null;
          }
        }));
  }
  updateReferralCemetery(ref:Referral, cem:Cemetery): Observable<number> {
    return this.http.post<number>(`${this.updateCemeteryForReferralForURL}`, { ref: ref, cemetery: cem })
        .pipe(map((res) => {
          if(res) {
            return res;
          } else {
            return null;
          }
        }));
  }
  updateReferralDetails(ref:Referral, det:Referraldetails): Observable<number> {
    return this.http.post<number>(`${this.updateReferralDetailsForURL}`, { ref: ref, referraldetails: det })
        .pipe(map((res) => {
          if(res) {
            return res;
          } else {
            return null;
          }
        }));
  }
  finalizeReferral(ref:Referral): Observable<number> {
    return this.http.post<number>(`${this.finalizeAndSendReferralURL}`, { ref: ref })
        .pipe(map((res) => {
          if(res) {
            return res;
          } else {
            return null;
          }
        }));
  }  

}
