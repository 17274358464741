import { Component, OnInit } from '@angular/core';
import { NgxMasonryOptions } from 'ngx-masonry';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-gallery-monument-bench',
  templateUrl: './gallery-monument-bench.component.html',
  styleUrls: ['./gallery-monument-bench.component.css']
})
export class GalleryMonumentBenchComponent implements OnInit {

  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;
  footerVariant = 'footer-four';
  
  private _album = [];
  /***
   * Masonry Option
   */
  public myOptions: NgxMasonryOptions = {
    horizontalOrder: true
  };

  /**
   * Portfolio Masonry Five Data
   */
  filterredImages;
  galleryFilter = 'all';
  list = [
  {
    image: 'bench-other-001.jpg',
    title: 'Bench Monument',
    text: 'Other',
    category: 'Other'
  },
  {
    image: 'bench-other-002.jpg',
    title: 'Bench Monument',
    text: 'Other',
    category: 'Other'
  },
  {
    image: 'bench-other-003.jpg',
    title: 'Bench Monument',
    text: 'Other',
    category: 'Other'
  },
  {
    image: 'bench-other-003a.jpg',
    title: 'Bench Monument',
    text: 'Other',
    category: 'Other'
  },
  {
    image: 'bench-other-004.jpg',
    title: 'Bench Monument',
    text: 'Other',
    category: 'Other'
  },
  {
    image: 'bench-other-005.jpg',
    title: 'Bench Monument',
    text: 'Other',
    category: 'Other'
  },
  {
    image: 'bench-other-006.jpg',
    title: 'Bench Monument',
    text: 'Other',
    category: 'Other'
  },
  {
    image: 'bench-other-007.jpg',
    title: 'Bench Monument',
    text: 'Other',
    category: 'Other'
  },
  {
    image: 'bench-park-003.jpg',
    title: 'Bench Monument',
    text: 'Park',
    category: 'Park'
  },
  {
    image: 'bench-park-004.jpg',
    title: 'Bench Monument',
    text: 'Park',
    category: 'Park'
  },  {
    image: 'bench-pedestal-001.jpg',
    title: 'Bench Monument',
    text: 'Pedestal',
    category: 'Pedestal'
  },
  {
    image: 'bench-pedestal-002.jpg',
    title: 'Bench Monument',
    text: 'Pedestal',
    category: 'Pedestal'
  },
  {
    image: 'bench-pedestal-003.jpg',
    title: 'Bench Monument',
    text: 'Pedestal',
    category: 'Pedestal'
  },
  {
    image: 'bench-pedestal-005.jpg',
    title: 'Bench Monument',
    text: 'Pedestal',
    category: 'Pedestal'
  },
  {
    image: 'bench-pedestal-006.jpg',
    title: 'Bench Monument',
    text: 'Pedestal',
    category: 'Pedestal'
  },
  {
    image: 'bench-pedestal-007.jpg',
    title: 'Bench Monument',
    text: 'Pedestal',
    category: 'Pedestal'
  },
  {
    image: 'bench-pedestal-008.jpg',
    title: 'Bench Monument',
    text: 'Pedestal',
    category: 'Pedestal'
  },
  {
    image: 'bench-pedestal-009.jpg',
    title: 'Bench Monument',
    text: 'Pedestal',
    category: 'Pedestal'
  },  {
    image: 'bench-traditional-001.jpg',
    title: 'Bench Monument',
    text: 'Traditional',
    category: 'Traditional'
  },
  {
    image: 'bench-traditional-002.jpg',
    title: 'Bench Monument',
    text: 'Traditional',
    category: 'Traditional'
  },
  {
    image: 'bench-traditional-003.jpg',
    title: 'Bench Monument',
    text: 'Traditional',
    category: 'Traditional'
  },
  {
    image: 'bench-traditional-005.jpg',
    title: 'Bench Monument',
    text: 'Traditional',
    category: 'Traditional'
  },
  {
    image: 'bench-traditional-006.jpg',
    title: 'Bench Monument',
    text: 'Traditional',
    category: 'Traditional'
  },
  {
    image: 'bench-traditional-007.jpg',
    title: 'Bench Monument',
    text: 'Traditional',
    category: 'Traditional'
  },  {
    image: 'cremation-bench-001.jpg',
    title: 'Bench Monument',
    text: 'Cremation',
    category: 'Cremation'
  },
  {
    image: 'cremation-bench-002.jpg',
    title: 'Bench Monument',
    text: 'Cremation',
    category: 'Cremation'
  },
  {
    image: 'cremation-bench-004.jpg',
    title: 'Bench Monument',
    text: 'Cremation',
    category: 'Cremation'
  },
  {
    image: 'cremation-bench-005.jpg',
    title: 'Bench Monument',
    text: 'Cremation',
    category: 'Cremation'
  },
  {
    image: 'cremation-bench-bronze.jpg',
    title: 'Bench Monument',
    text: 'Cremation',
    category: 'Cremation'
  },
  {
    image: 'cremation-bench-bronzeii.jpg',
    title: 'Bench Monument',
    text: 'Cremation',
    category: 'Cremation'
  },
  {
    image: 'cremation-bench-bronzeiii.jpg',
    title: 'Bench Monument',
    text: 'Cremation',
    category: 'Cremation'
  },
  {
    image: 'cremation-bench-bronzeiv.jpg',
    title: 'Bench Monument',
    text: 'Cremation',
    category: 'Cremation'
  },
  ];

  constructor(private _lightbox: Lightbox) {}

  ngOnInit(): void {
    this.filterredImages = this.list;
    this.resortImages();
  }

  resortImages() {
    this._album = [];
    for (let i = 0; i <= this.filterredImages.length; i++) {
      const src = 'assets/images/monuments/' + this.filterredImages[i].image;
      const caption = 'Image ' + this.filterredImages[i].title;
      const thumb = 'assets/images/monuments/' + this.filterredImages[i].image;
      const item = {
        src: src,
        caption: caption,
        thumb: thumb
      };
      this._album.push(item);
    }
  }

  activeCategory(category) {
    this.galleryFilter = category;
    if (this.galleryFilter === 'all') {
      this.filterredImages = this.list;
      this.resortImages();
    } else {
      this.filterredImages = this.list.filter(x => x.category === this.galleryFilter);
      this.resortImages();
    }
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._album, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

}
