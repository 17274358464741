<!-- Hero Start -->
<section class="bg-half-100 d-table w-100" id="home">
    <div class="container">
        <div class="row justify-content-center mt-5">
            <div class="col-lg-8">
                <div class="section-title">
                    <h4 class="title fw-medium mb-4 mt-2">Onsite CLeaning</h4>
                    <p class="text-muted mb-5">Memorial Cleaning Onsite: Our dedicated team rejuvenates each monument with meticulous care and expertise. Employing gentle yet effective cleaning techniques, we restore the luster of time-worn memorials. With precision and respect, we remove debris, algae, and discoloration, revealing the inherent beauty of the material. Witness the transformation as memories are unveiled, honoring the legacy with a fresh, dignified appearance, a fitting tribute for generations to come.</p>
                
                    <img src="assets/images/cleaning-home_2.jpg" class="img-fluid rounded shadow" alt="">
                    <img src="assets/images/cleaning-home.jpg" class="img-fluid rounded shadow" style="margin-top: 12px;margin-bottom: 12px;" alt="">
                    <button routerLink="/page-book-appointment" type="submit" id="submit" name="send" class="btn btn-primary">SCHEDULE A CLEANING APPOINTMENT</button>

                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end Container-->
</section><!--end section-->
