<!-- Start -->
<section class="bg-half-170 d-table bg-primary w-100" id="home"
    style="background-image: url('assets/images/cemeteries/header_001.jpg');">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col">
                <div class="pages-heading title-heading text-center">
                    <h4 class="display-6 text-white title-dark mb-4 fw-bold">Find Your Cemetery</h4>
                    <p class="text-white-50 para-desc mx-auto mb-0">Search for your cemetery here.<br>If you cannot find it, dont worry we will for you.</p>
                    <div class="text-center subcribe-form mt-4 pt-2">
                        <!-- SPINNER -->
                        <ng-container *ngIf="searching">
                            <div class="spinner">
                                <svg id="a" xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39"><circle cx="19.5" cy="19.5" r="9" fill="#ffffff" opacity=".1"/><g opacity=".8"><circle cx="19.5" cy="2.8" r="2.8" fill="#ffffff"/></g><g opacity=".4"><circle cx="19.5" cy="36.2" r="2.8" fill="#ffffff"/></g><g opacity=".9"><circle cx="31.31" cy="7.69" r="2.8" fill="#ffffff"/></g><g opacity=".5"><circle cx="7.69" cy="31.31" r="2.8" fill="#ffffff"/></g><circle cx="36.2" cy="19.5" r="2.8" fill="#ffffff"/><g opacity=".6"><circle cx="2.8" cy="19.5" r="2.8" fill="#ffffff"/></g><g opacity=".3"><circle cx="31.31" cy="31.31" r="2.8" fill="#ffffff"/></g><g opacity=".7"><circle cx="7.69" cy="7.69" r="2.8" fill="#ffffff"/></g></svg>
                            </div>
                        </ng-container> 
                        <!-- END SPINNER -->                       
                        <form>
                            <input type="text" id="searchForTerm" name="searchForTerm" [(ngModel)]="searchForTerm" class="rounded-pill bg-white-50" placeholder="Search for your cemetery by name...">
                            <button type="submit" class="btn btn-pills btn-primary" (click)="initialSearch()" [disabled]="searchForTerm == null || searchForTerm.length <= 1">Search <i class="uil uil-arrow-right"></i></button>
                        </form><!--end form-->

<h6 *ngIf="noresults" style="color:white;margin:20px 0;">Sorry, we did not find your cemetery. Please try again with different criteria.</h6>

                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->
<!-- End -->

<!-- Start -->
<ng-container *ngIf="!cemeteries">
<section class="section" style="padding:20px 0;">
    <div class="container mt-60">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title mb-3">We work with any cemetery.</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Across the entire US.
                    <span class="text-primary fw-bold">Richardon Monument</span> will work with your cemtery to assure your monument is installed professionally. If you dont see your cemetery here, dont worry, just reach out, let us know the name, city and state. We will take it from there.
                    </p>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section>
</ng-container>
<!-- End -->

<ng-container *ngIf="cemeteries">
<section class="section" id="price">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 mt-md-5 pt-md-3 mt-lg-0 pt-lg-0">
                <div class="table-responsive bg-white shadow rounded features-absolute">
                    <h6 style="text-align:center;margin:14px;">{{cemeteries.length}} FOUND</h6>
                    <table class="table mb-0 table-center">
                        <thead>
                            <tr class="bg-light">
                                <th scope="col" class="border-bottom tbhd" style="min-width: 250px;">Name</th>
                                <th scope="col" class="border-bottom tbhd text-left" style="min-width: 80px;">Address</th>
                                <th scope="col" class="border-bottom tbhd text-center" style="min-width: 80px;">Contact</th>
                                <th scope="col" class="border-bottom tbhd text-center" style="min-width: 100px;">Link</th>
                            </tr>
                        </thead>
                        <tbody>


<ng-container *ngFor="let cem of cemeteries">
                            <tr>
                                <th class="red-txt">{{cem.cemeteryName}}</th>
                                <td class=" text-left">{{cem.cemeteryAddressOne}} {{cem.cemeteryCity}}, {{cem.cemeteryState}}. {{cem.cemeteryZip}}</td>
                                <td class=" text-center">
                                    <ng-container *ngIf="cem.cemeteryPhone">
                                        {{cem.cemeteryPhone}}
                                    </ng-container>
                                    <ng-container *ngIf="!cem.cemeteryPhone">
                                        ...
                                    </ng-container>
                                </td>
                                <td class=" text-center">
                                    <ng-container *ngIf="cem.cemeteryURL">
                                        <a [href]="cem.cemeteryURL" target="_blank" class="btn btn-sm btn-soft-primary">VIEW</a>
                                    </ng-container>
                                    <ng-container *ngIf="!cem.cemeteryURL">
                                        ...
                                    </ng-container>
                                </td>
                            </tr>
</ng-container>                            
                        </tbody>
                    </table>
                    <!--end table-->
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
</ng-container>
<!--end section-->
<!-- Pricing End -->

<!-- Back to top -->
<!-- <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a> -->
<!-- Back to top -->