import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-book-appointment',
  templateUrl: './page-book-appointment.component.html',
  styleUrls: ['./page-book-appointment.component.css']
})
export class PageBookAppointmentComponent implements OnInit {

  @ViewChild('targetDiv') targetDiv!: ElementRef<HTMLDivElement>;
  navClass = 'nav-light';
  Menuoption = 'center';
  Settingicon = true;
  footerVariant = 'footer-four';

  showMaine = false;
  showMass = false;
  showNH = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  bookAppointment(loc:string) {
    switch(loc){ 
      case "me-aub":
        this.router.navigate(['/book-now/Auburn Maine']);
        break;
      case "me-sopo":
        window.open("https://squareup.com/appointments/book/bfe49g6oct4tdg/KFRYT3B1P3DT9/services", '_blank', 'location=yes,scrollbars=yes,status=yes');
        break;
      case "me-bidd":
        window.open("https://squareup.com/appointments/book/bfe49g6oct4tdg/L0MZ4NXAHZCTH/services", '_blank', 'location=yes,scrollbars=yes,status=yes');
        break;
      case "nh-roch":
        window.open("https://squareup.com/appointments/book/bfe49g6oct4tdg/LXBM97TC2A0CE/services", '_blank', 'location=yes,scrollbars=yes,status=yes');
        break;
      case "nh-ports":
        window.open("https://squareup.com/appointments/book/bfe49g6oct4tdg/LXSEP2XHTD1T5/services", '_blank', 'location=yes,scrollbars=yes,status=yes');
        break;
      case "nh-bed":
        this.router.navigate(['/book-now/Greater Manchester New Hampshire']);
        break;
      case "nh-nashua":
        this.router.navigate(['/book-now/Nashua New Hampshire']);
        break;
      case "nh-concord":
        this.router.navigate(['/book-now/Concord New Hampshire']);
        break;
      case "nh-keene":
        this.router.navigate(['/book-now/Keene New Hampshire']);
        break;
      case "ma-andover":
        this.router.navigate(['/book-now/Andover Massachusetts']);
        break;
      case "ma-burlington":
        this.router.navigate(['/book-now/Burlington Massachusetts']);
        break;
      case "ma-concord":
        this.router.navigate(['/book-now/Concord Massachusetts']);
        break;
      case "ma-peabody":
        this.router.navigate(['/book-now/Peabody Massachusetts']);
        break;
      case "ma-wakefield":
        this.router.navigate(['/book-now/Wakefield Massachusetts']);
        break;
      case "ma-middleton":
        this.router.navigate(['/book-now/Middleton Massachusetts']);
        break;
      case "ma-cambridge":
        this.router.navigate(['/book-now/Cambridge Massachusetts']);
        break;
      case "ma-charlestown":
        this.router.navigate(['/book-now/Charlestown Massachusetts']);
        break;
      case "ma-newton":
        this.router.navigate(['/book-now/Newton Massachusetts']);
        break;
      case "ma-somerville":
        this.router.navigate(['/book-now/Somerville Massachusetts']);
        break;
      case "ma-canton":
        this.router.navigate(['/book-now/Canton Massachusetts']);
        break;
      case "ma-dedham":
        this.router.navigate(['/book-now/Dedham Massachusetts']);
        break;
      case "ma-milton":
        this.router.navigate(['/book-now/Milton Massachusetts']);
        break;
      case "ma-quincy":
        this.router.navigate(['/book-now/Quincy Massachusetts']);
        break;
      case "ma-waltham":
        this.router.navigate(['/book-now/Waltham Massachusetts']);
        break;
      case "ma-hingham":
        this.router.navigate(['/book-now/Hingham Massachusetts']);
        break;
      case "ma-marshfield":
        this.router.navigate(['/book-now/Marshfield Massachusetts']);
        break;
      case "ma-framingham":
        this.router.navigate(['/book-now/Framingham Massachusetts']);
        break; 
      case "ma-marlborough":
        this.router.navigate(['/book-now/Marlborough Massachusetts']);
        break; 
      case "ma-northborough":
        this.router.navigate(['/book-now/Northborough Massachusetts']);
        break;         
      case "zoom":
        window.open("https://squareup.com/appointments/book/bfe49g6oct4tdg/LCY16T44NNQ2X/services", '_blank', 'location=yes,scrollbars=yes,status=yes');
        break;    
      default:
        break;
    }
  }

  filterLocations(loc:string) {
    switch ( loc ) {
       case 'me':
            this.showMaine = true;
            this.showMass = false;
            this.showNH = false;
           break;
       case 'ma':
            this.showMaine = false;
            this.showMass = true;
            this.showNH = false;
           break;
       case 'nh':
            this.showMaine = false;
            this.showMass = false;
            this.showNH = true;
           break;
       default: 
            this.showMaine = false;
            this.showMass = false;
            this.showNH = false;
           break;
    }
    this.targetDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }  

}
