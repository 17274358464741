import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { combineLatest } from 'rxjs/operators';

// INDEX
import { MasterPageComponent } from './core/components/master-page/master-page.component';
import { IndexRichardsonMonumentComponent } from './core/components/index-richardson-monument/index-richardson-monument.component';
// ME
import { ContactSouthPortlandMeComponent } from './core/components/contact-south-portland-me/contact-south-portland-me.component';
import { ContactBiddefordMeComponent } from './core/components/contact-biddeford-me/contact-biddeford-me.component';
import { ContactAuburnMeComponent } from './core/components/contact-auburn-me/contact-auburn-me.component';
// NH
import { ContactRochesterNhComponent } from './core/components/contact-rochester-nh/contact-rochester-nh.component';
import { ContactPortsmouthNhComponent } from './core/components/contact-portsmouth-nh/contact-portsmouth-nh.component';
import { ContactManchesterNhComponent } from './core/components/contact-manchester-nh/contact-manchester-nh.component';
import { ContactConcordNhComponent } from './core/components/contact-concord-nh/contact-concord-nh.component';
import { ContactNashuaNhComponent } from './core/components/contact-nashua-nh/contact-nashua-nh.component';
import { ContactKeeneNhComponent } from './core/components/contact-keene-nh/contact-keene-nh.component';
// MASS
import { ContactAndoverMaComponent } from './core/components/contact-andover-ma/contact-andover-ma.component';
import { ContactBurlingtonMaComponent } from './core/components/contact-burlington-ma/contact-burlington-ma.component';
import { ContactPeabodyMaComponent } from './core/components/contact-peabody-ma/contact-peabody-ma.component';
import { ContactConcordMaComponent } from './core/components/contact-concord-ma/contact-concord-ma.component';
import { ContactWakefieldMaComponent } from './core/components/contact-wakefield-ma/contact-wakefield-ma.component';
import { ContactMiddletonMaComponent } from './core/components/contact-middleton-ma/contact-middleton-ma.component';
import { ContactCambridgeMaComponent } from './core/components/contact-cambridge-ma/contact-cambridge-ma.component';
import { ContactCharlestownMaComponent } from './core/components/contact-charlestown-ma/contact-charlestown-ma.component';
import { ContactNewtonMaComponent } from './core/components/contact-newton-ma/contact-newton-ma.component';
import { ContactSomervilleMaComponent } from './core/components/contact-somerville-ma/contact-somerville-ma.component';
import { ContactCantonMaComponent } from './core/components/contact-canton-ma/contact-canton-ma.component';
import { ContactDedhamMaComponent } from './core/components/contact-dedham-ma/contact-dedham-ma.component';
import { ContactMiltonMaComponent } from './core/components/contact-milton-ma/contact-milton-ma.component';
import { ContactQuincyMaComponent } from './core/components/contact-quincy-ma/contact-quincy-ma.component';
import { ContactWalthamMaComponent } from './core/components/contact-waltham-ma/contact-waltham-ma.component';
import { ContactHinghamMaComponent } from './core/components/contact-hingham-ma/contact-hingham-ma.component';
import { ContactMarshfieldMaComponent } from './core/components/contact-marshfield-ma/contact-marshfield-ma.component';
import { ContactNorthboroughMaComponent } from './core/components/contact-northborough-ma/contact-northborough-ma.component';
import { ContactMarlboroughMaComponent } from './core/components/contact-marlborough-ma/contact-marlborough-ma.component';
import { ContactFraminghamMaComponent } from './core/components/contact-framingham-ma/contact-framingham-ma.component';

// galleries
import { GalleryMonumentUprightComponent } from './core/components/gallery-monument-upright/gallery-monument-upright.component';
import { GalleryMonumentFlatComponent } from './core/components/gallery-monument-flat/gallery-monument-flat.component';
import { GalleryMonumentBenchComponent } from './core/components/gallery-monument-bench/gallery-monument-bench.component';
import { GalleryMonumentBronzeComponent } from './core/components/gallery-monument-bronze/gallery-monument-bronze.component';
import { GalleryMonumentCivicJobsComponent } from './core/components/gallery-monument-civic-jobs/gallery-monument-civic-jobs.component';
import { GalleryMonumentCremationComponent } from './core/components/gallery-monument-cremation/gallery-monument-cremation.component';
import { GalleryMonumentCustomComponent } from './core/components/gallery-monument-custom/gallery-monument-custom.component';
import { GalleryMonumentMausoleumsComponent } from './core/components/gallery-monument-mausoleums/gallery-monument-mausoleums.component';
import { GalleryMonumentOnsiteCleaningComponent } from './core/components/gallery-monument-onsite-cleaning/gallery-monument-onsite-cleaning.component';
import { GalleryMonumentOnsiteEngravingComponent } from './core/components/gallery-monument-onsite-engraving/gallery-monument-onsite-engraving.component';
import { GalleryMonumentSlantComponent } from './core/components/gallery-monument-slant/gallery-monument-slant.component';

// cemetery pages
import { PageCemeteriesHomeComponent } from './core/components/page-cemeteries-home/page-cemeteries-home.component';
import { PageBookAppointmentComponent } from './core/components/page-book-appointment/page-book-appointment.component';
import { ContactUsHomeComponent } from './core/components/contact-us-home/contact-us-home.component';
// OTHER PAGES
import { ReferralInHomeComponent } from './core/components/referral-in-home/referral-in-home.component';
import { PageBookAppointmentFormComponent } from './core/components/page-book-appointment-form/page-book-appointment-form.component';

const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [
	    { path: '', component: IndexRichardsonMonumentComponent },
	    { path: 'index', component: IndexRichardsonMonumentComponent },
	    { path: 'contact-south-portland-me', component: ContactSouthPortlandMeComponent },
		  { path: 'contact-biddeford-me', component: ContactBiddefordMeComponent },
		  { path: 'contact-auburn-me', component: ContactAuburnMeComponent },
		  { path: 'contact-rochester-nh', component: ContactRochesterNhComponent },
		  { path: 'contact-portsmouth-nh', component: ContactPortsmouthNhComponent },
		  { path: 'contact-manchester-nh', component: ContactManchesterNhComponent },
		  { path: 'contact-concord-nh', component: ContactConcordNhComponent },
		  { path: 'contact-nashua-nh', component: ContactNashuaNhComponent },
		  { path: 'contact-keene-nh', component: ContactKeeneNhComponent },
			{ path: 'contact-andover-ma', component: ContactAndoverMaComponent },
			{ path: 'contact-burlington-ma', component: ContactBurlingtonMaComponent },
			{ path: 'contact-peabody-ma', component: ContactPeabodyMaComponent },
			{ path: 'contact-concord-ma', component: ContactConcordMaComponent },
		  { path: 'contact-wakefield-ma', component: ContactWakefieldMaComponent },
		  { path: 'contact-middleton-ma', component: ContactMiddletonMaComponent },
		  { path: 'contact-cambridge-ma', component: ContactCambridgeMaComponent },
		  { path: 'contact-charlestown-ma', component: ContactCharlestownMaComponent },
		  { path: 'contact-newton-ma', component: ContactNewtonMaComponent },
		  { path: 'contact-somerville-ma', component: ContactSomervilleMaComponent },
		  { path: 'contact-canton-ma', component: ContactCantonMaComponent },
		  { path: 'contact-dedham-ma', component: ContactDedhamMaComponent },
		  { path: 'contact-milton-ma', component: ContactMiltonMaComponent },
		  { path: 'contact-quincy-ma', component: ContactQuincyMaComponent },
		  { path: 'contact-waltham-ma', component: ContactWalthamMaComponent },
			{ path: 'contact-hingham-ma', component: ContactHinghamMaComponent },
			{ path: 'contact-marshfield-ma', component: ContactMarshfieldMaComponent },
			{ path: 'contact-northborough-ma', component: ContactNorthboroughMaComponent },
			{ path: 'contact-marlborough-ma', component: ContactMarlboroughMaComponent },
			{ path: 'contact-framingham-ma', component: ContactFraminghamMaComponent },
		  { path: 'search-cemetery-home', component: PageCemeteriesHomeComponent },
		  { path: 'page-book-appointment', component: PageBookAppointmentComponent },
		  { path: 'referral-in-home', component: ReferralInHomeComponent },
		  { path: 'gallery-monument-upright', component: GalleryMonumentUprightComponent },
		  { path: 'gallery-monument-flat', component: GalleryMonumentFlatComponent },
		  { path: 'gallery-monument-bench', component: GalleryMonumentBenchComponent },
		  { path: 'gallery-monument-bronze', component: GalleryMonumentBronzeComponent },
		  { path: 'gallery-monument-civic-jobs', component: GalleryMonumentCivicJobsComponent },
		  { path: 'gallery-monument-cremation', component: GalleryMonumentCremationComponent },
		  { path: 'gallery-monument-custom', component: GalleryMonumentCustomComponent },
		  { path: 'gallery-monument-mausoleums', component: GalleryMonumentMausoleumsComponent },
		  { path: 'gallery-monument-onsite-cleaning', component: GalleryMonumentOnsiteCleaningComponent },
		  { path: 'gallery-monument-onsite-engraving', component: GalleryMonumentOnsiteEngravingComponent },
		  { path: 'gallery-monument-slant', component: GalleryMonumentSlantComponent },
		  { path: 'contact-us-home', component: ContactUsHomeComponent },
		  { path: 'book-now/:loc', component: PageBookAppointmentFormComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top",
  scrollOffset: [0, 0],
  // Enable scrolling to anchors
  anchorScrolling: "enabled"})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
