export class MessageContact {

	constructor(
		public name: string,
		public email: string,
		public subject: string,
		public message: string,
		public toLocation: string,
		public phone: string
	) {}

}