import { Component, OnChanges, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ReferralService } from '../../../../services/referral.service';
import { CookieService } from 'ngx-cookie-service';

import { RefCustomer } from '../../../../objectmodels/refCustomer';
import { Referral } from '../../../../objectmodels/referral';
import { Referraldetails } from '../../../../objectmodels/referraldetails';


@Component({
  selector: 'app-referral-finalize',
  templateUrl: './referral-finalize.component.html',
  styleUrls: ['./referral-finalize.component.css']
})
export class ReferralFinalizeComponent implements OnInit {

  referral:Referral=null;
  @Input() refID : number;
  @Input() refTOKEN : string;
  @Output() referralFinalized = new EventEmitter();

  initUpload:boolean = false;
  isComplete:boolean = false;

  constructor(
    public referralService: ReferralService,
    private cookieService: CookieService
  ) {
    const currentRef = new Referral(
      this.refID,
      this.refTOKEN,
      ""
    );
    this.referral = currentRef;
  }

  ngOnInit(): void {
  
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.refID) {
      const currentRef = new Referral(
        this.refID,
        this.refTOKEN,
        ""
      );
      this.referral = currentRef;
      // console.dir(this.referral);
    }

    if (changes.refTOKEN) {
      const currentRef = new Referral(
        this.refID,
        this.refTOKEN,
        ""
      );
      this.referral = currentRef;
      // console.dir(this.referral);
    }
  }

  finalizeClicked(){
    console.log("FINALIZE CLICKED PARENT");
    if (this.initUpload) {
      this.initUpload=false;
    } else {
      this.initUpload=true;
    }
  }

  finishSubmission() {
    // console.log("FINISH THE FORM");
    let cemSectionLotInfo = document.forms["finalizeForm"]["cemSectionLotInfo"].value;
    let orderDetails = document.forms["finalizeForm"]["orderDetails"].value;
    let contactNotes = document.forms["finalizeForm"]["contactNotes"].value;
    let reachouttocustomer = document.forms["finalizeForm"]["reachouttocustomer"].value;
    // console.log("cemSectionLotInfo == "+cemSectionLotInfo);
    // console.log("orderDetails == "+orderDetails);
    // console.log("contactNotes == "+contactNotes);
    // console.log("reachouttocustomer == "+reachouttocustomer);
    const referraldetails = new Referraldetails(
      cemSectionLotInfo,
      orderDetails,
      contactNotes,
      reachouttocustomer
    );
    // alert(JSON.stringify(referraldetails));
    this.referralService.updateReferralDetails(this.referral, referraldetails)
    .subscribe(results => {
      if (results) {
        // alert("HEY MAN YOU MADE IT HERE");
        this.finalizeReferral();
      } else {
        alert("Sorry, \n\nThere was an error finalizing your referral. Please try again and check that you provided the required information. If the problem persists please contact us and let us know.");
      }
    });
  }

  finalizeReferral() {
    this.referralService.finalizeReferral(this.referral)
    .subscribe(results => {
      if (results == 1) {
        this.referralFinalized.emit();
      } else {
         alert("Sorry, \n\nThere was an error finalizing your referral. Please try again and check that you provided the required information. If the problem persists please contact us and let us know.");
      }
    });
  }

}
