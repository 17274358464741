import { Component, OnInit } from '@angular/core';
import { NgxMasonryOptions } from 'ngx-masonry';
import { Lightbox } from 'ngx-lightbox';


@Component({
  selector: 'app-gallery-monument-slant',
  templateUrl: './gallery-monument-slant.component.html',
  styleUrls: ['./gallery-monument-slant.component.css']
})
export class GalleryMonumentSlantComponent implements OnInit {

  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;
  footerVariant = 'footer-four';
  
  private _album = [];
  /***
   * Masonry Option
   */
  public myOptions: NgxMasonryOptions = {
    horizontalOrder: true
  };

  /**
   * Portfolio Masonry Five Data
   */
  filterredImages;
  galleryFilter = 'all';
  list = [
    {
    image: 'slant-marker-blue-001.jpg',
    title: 'Slant Monument',
    text: 'Blue',
    category: 'Blue'
  },  {
    image: 'slant-marker-gray-001.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-002.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-003.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-004.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-005.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-006.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-007.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-008.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-010.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-011.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-012.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-013.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-014.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-015.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-016.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-017.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-018.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-019.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-020.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-021.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-022.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-023.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-024.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-025.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-026.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-027.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-028.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-029.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-030.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-031.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },
  {
    image: 'slant-marker-gray-032.jpg',
    title: 'Slant Monument',
    text: 'Gray',
    category: 'Gray'
  },  {
    image: 'slant-marker-gray-unpolished-001.jpg',
    title: 'Slant Monument',
    text: 'Gray Unpolished',
    category: 'Gray Unpolished'
  },
  {
    image: 'slant-marker-gray-unpolished-002.jpg',
    title: 'Slant Monument',
    text: 'Gray Unpolished',
    category: 'Gray Unpolished'
  },  {
    image: 'slant-marker-impala-001.jpg',
    title: 'Slant Monument',
    text: 'Impala',
    category: 'Impala'
  },
  {
    image: 'slant-marker-impala-002.jpg',
    title: 'Slant Monument',
    text: 'Impala',
    category: 'Impala'
  },{
    image: 'slant-marker-jetblack-001.jpg',
    title: 'Slant Monument',
    text: 'Jet Black',
    category: 'Jet Black'
  },
  {
    image: 'slant-marker-jetblack-002.jpg',
    title: 'Slant Monument',
    text: 'Jet Black',
    category: 'Jet Black'
  },
  {
    image: 'slant-marker-jetblack-003.jpg',
    title: 'Slant Monument',
    text: 'Jet Black',
    category: 'Jet Black'
  },
  {
    image: 'slant-marker-jetblack-004.jpg',
    title: 'Slant Monument',
    text: 'Jet Black',
    category: 'Jet Black'
  },{
    image: 'slant-marker-rose-001.jpg',
    title: 'Slant Monument',
    text: 'Rose',
    category: 'Rose'
  },
  {
    image: 'slant-marker-rose-002.jpg',
    title: 'Slant Monument',
    text: 'Rose',
    category: 'Rose'
  },
  {
    image: 'slant-marker-rose-003.jpg',
    title: 'Slant Monument',
    text: 'Rose',
    category: 'Rose'
  },
  {
    image: 'slant-marker-rose-004.jpg',
    title: 'Slant Monument',
    text: 'Rose',
    category: 'Rose'
  },
  {
    image: 'slant-marker-rose-005.jpg',
    title: 'Slant Monument',
    text: 'Rose',
    category: 'Rose'
  },
  {
    image: 'slant-marker-rose-006.jpg',
    title: 'Slant Monument',
    text: 'Rose',
    category: 'Rose'
  },
  {
    image: 'slant-marker-rose-007.jpg',
    title: 'Slant Monument',
    text: 'Rose',
    category: 'Rose'
  },
  {
    image: 'slant-marker-rose-008.jpg',
    title: 'Slant Monument',
    text: 'Rose',
    category: 'Rose'
  },
  {
    image: 'slant-marker-rose-009.jpg',
    title: 'Slant Monument',
    text: 'Rose',
    category: 'Rose'
  },
  {
    image: 'slant-marker-rose-010.jpg',
    title: 'Slant Monument',
    text: 'Rose',
    category: 'Rose'
  },
  {
    image: 'slant-marker-rose-011.jpg',
    title: 'Slant Monument',
    text: 'Rose',
    category: 'Rose'
  },
  {
    image: 'slant-marker-rose-012.jpg',
    title: 'Slant Monument',
    text: 'Rose',
    category: 'Rose'
  },
  {
    image: 'slant-marker-rose-013.jpg',
    title: 'Slant Monument',
    text: 'Rose',
    category: 'Rose'
  },
  {
    image: 'slant-marker-rose-014.jpg',
    title: 'Slant Monument',
    text: 'Rose',
    category: 'Rose'
  },
  {
    image: 'slant-marker-rose-015.jpg',
    title: 'Slant Monument',
    text: 'Rose',
    category: 'Rose'
  },
  {
    image: 'slant-marker-rose-016.jpg',
    title: 'Slant Monument',
    text: 'Rose',
    category: 'Rose'
  },
  {
    image: 'slant-marker-rose-017.jpg',
    title: 'Slant Monument',
    text: 'Rose',
    category: 'Rose'
  },
  {
    image: 'slant-marker-rose-018.jpg',
    title: 'Slant Monument',
    text: 'Rose',
    category: 'Rose'
  },
  {
    image: 'slant-marker-rose-019.jpg',
    title: 'Slant Monument',
    text: 'Rose',
    category: 'Rose'
  },
  {
    image: 'slant-marker-rose-020.jpg',
    title: 'Slant Monument',
    text: 'Rose',
    category: 'Rose'
  },
  {
    image: 'slant-marker-rose-021.jpg',
    title: 'Slant Monument',
    text: 'Rose',
    category: 'Rose'
  },
  {
    image: 'slant-marker-rose-022.jpg',
    title: 'Slant Monument',
    text: 'Rose',
    category: 'Rose'
  },
  {
    image: 'slant-marker-rose-023.jpg',
    title: 'Slant Monument',
    text: 'Rose',
    category: 'Rose'
  },
  ];

  constructor(private _lightbox: Lightbox) {}

  ngOnInit(): void {
    this.filterredImages = this.list;
    this.resortImages();
  }

  resortImages() {
    this._album = [];
    for (let i = 0; i <= this.filterredImages.length; i++) {
      const src = 'assets/images/monuments/' + this.filterredImages[i].image;
      const caption = 'Image ' + this.filterredImages[i].title;
      const thumb = 'assets/images/monuments/' + this.filterredImages[i].image;
      const item = {
        src: src,
        caption: caption,
        thumb: thumb
      };
      this._album.push(item);
    }
  }

  activeCategory(category) {
    this.galleryFilter = category;
    if (this.galleryFilter === 'all') {
      this.filterredImages = this.list;
      this.resortImages();
    } else {
      this.filterredImages = this.list.filter(x => x.category === this.galleryFilter);
      this.resortImages();
    }
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._album, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

}
