import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsService } from '../../../services/contact-us.service';
import { MessageStatus } from'../../../objectmodels/messageStatus';
import { MessageContact } from'../../../objectmodels/messageContact';

import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-burlington-ma',
  templateUrl: './contact-burlington-ma.component.html',
  styleUrls: ['./contact-burlington-ma.component.css']
})
export class ContactBurlingtonMaComponent implements OnInit {

  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;
  footerVariant = 'footer-four';
  sendingMessage = null;
  mesgBTNdisabled = false;
  sendMessageResults : MessageStatus = null;
  showMessageForm : boolean = true;

  constructor(
    private modalService: NgbModal, 
    public contactUsService: ContactUsService,
    private router: Router
  ) { }

  ngOnInit(): void {

  }

  validateForm() {
    let name = document.forms["myForm"]["name"].value;
    let email = document.forms["myForm"]["email"].value;
    let subject = document.forms["myForm"]["subject"].value;
    let message = document.forms["myForm"]["comments"].value;
    let phone = document.forms["myForm"]["phone"].value;
    let toLocation = "ma-burlington";
    if (name == "" || name.length <= 2) {
      this.sendingMessage = "Please provide your name.";
      return false;
    }
    if (this.isEmail(email) == false) {
      this.sendingMessage = "Please provide a valid email address.";
      return false;
    }
    if (message == "" || message.length <= 5) {
      this.sendingMessage = "Dont forget your message.";
      return false;
    }
    const messageContact = new MessageContact(
      name,
      email,
      subject,
      message,
      toLocation,
      phone
    );
    this.sendingMessage = "SENDING";
    this.mesgBTNdisabled = true;
    this.contactUsService.sendContactMessage(messageContact)
    .subscribe(results => {
      if (results) {
        this.sendMessageResults = results;
        this.sendingMessage = this.sendMessageResults[0].MSSG;
        this.showMessageForm = false;
      }
      this.mesgBTNdisabled = false;
    });
  }

  isEmail(search:string):boolean {
      var serchfind:boolean;
      var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      var serchfind = regexp.test(search);
      console.log(serchfind);
      return serchfind;
  }

  mapView(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true })
  }

  bookAppointment(event) {
    // window.open("https://squareup.com/appointments/book/bfe49g6oct4tdg/L46R1HRA66M8G/services", '_blank', 'location=yes,scrollbars=yes,status=yes');
    this.router.navigate(['/book-now/Burlington Massachusetts']);
  }  



}
