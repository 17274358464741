export class Cemetery {

	constructor(
		public cemeteryPKID: number,
		public cemeteryName: string,
		public cemeteryAddressOne: string,
		public cemeteryAddressTwo: string,
		public cemeteryCity: string,
		public cemeteryState: string,
		public cemeteryZip: string,
		public cemeteryCountry: string,
		public cemeteryPhone: string,
		public cemeteryEmail: string,
		public cemeteryFax: string,
		public cemeteryProvince: string,
		public cemeteryURL: string,
		public cemeteryNotesI: string,
		public cemeteryNotesII: string,
		public groupPKID: number,
		public cemeteryLATTITUDE: string,
		public cemeteryLONGITUDE: string,
		public cemeteryStandardLotWidth: number,
		public cemeteryStandardLotLength: number,
		public cemeteryDigsFoundation: boolean,
		public cemeteryInstallsMonument: boolean,
		public cemeteryRecieveFoundationOrderPayment: boolean,
		public cemeteryInstallationNotes: string,
		public cemeteryMaxAcceptedMomumentWidthPercentage: number
	) {}

}