import { Component, OnInit } from '@angular/core';
import { CemeteryService } from '../../../services/cemetery.service';
import { MessageStatus } from'../../../objectmodels/messageStatus';
import { Cemetery } from '../../../objectmodels/cemetery';

@Component({
  selector: 'app-page-cemeteries-home',
  templateUrl: './page-cemeteries-home.component.html',
  styleUrls: ['./page-cemeteries-home.component.css']
})
export class PageCemeteriesHomeComponent implements OnInit {
  navClass = 'nav-light';
    // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;
  footerVariant = 'footer-four';
  searchForTerm:string=null;
  searching:boolean=null;
  cemeteries: Cemetery[];
  noresults:boolean=null;

  constructor(
    public cemeteryService: CemeteryService
  ) { }

  ngOnInit(): void {

  }

  initialSearch() {
    this.searching=true;
    let searchVal = this.searchForTerm;
    this.searchForTerm="";
    this.cemeteryService.searchCemeteries(searchVal)
    .subscribe(results => {
      if (results) {
        this.cemeteries = results;
        if (this.cemeteries.length <= 0) {
          this.noresults=true;
        } else {
          this.noresults=null;
        }
      } else {
        // some kind of error here
        this.noresults=true;
      }
      this.searching=false;
    });



  }

}