<!-- Hero Start -->
<section class="bg-half-100 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Gallery - Monument / Mausoleums </h4>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->


<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Hero End -->

<!-- Start -->
<section class="section">
    <div class="container-fluid">
        <div class="row">
            <h4 class="title mb-3" style="text-align: center;">Coming soon</h4>
            <!-- <ul class="container-filter list-inline mb-0 filter-options text-center" id="filter">
                <li class="list-inline-item categories-name border text-dark rounded ms-2" (click)="activeCategory('all')" [ngClass]="{'active': galleryFilter ==='all'}">All</li>
                <li class="list-inline-item categories-name border text-dark rounded ms-2" (click)="activeCategory('flat')" [ngClass]="{'active': galleryFilter ==='flat'}">Flat</li>
            </ul> -->
        </div>
        <!--end row-->

        <div class="projects-wrapper mt-4 pt-2">
            <ngx-masonry [options]="myOptions" class="row row-cols-lg-5">
                <div ngxMasonryItem class="col-lg col-md-6 col-12 spacing picture-item" *ngFor="let item of filterredImages;let i= index">
                    <div class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded">
                        <div class="card-body p-0">
                            <img src="assets/images/monuments/{{item.image}}" class="img-fluid" alt="work-image">
                            <div class="overlay-work bg-dark"></div>
                            <div class="content">
<!--                                 <h5 class="mb-0" style="color:white;">{{item.title}}</h5>
                                <h6 class="text-light tag mb-0">{{item.text}}</h6> -->
<a routerLink="/page-book-appointment" class="btn btn-primary btn-sm"> book apt. </a>
<a routerLink="/contact-us-home" class="btn btn-primary btn-sm" style="margin-left: 6px;"> contact us </a>
                            </div>
                            <div class="icons text-center">
                                <a (click)="open(i)" class="text-primary work-icon bg-white d-inline-block rounded-pill">
                                    <i-feather name="camera" class="fea icon-sm image-icon"></i-feather>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </ngx-masonry>
        </div>
        <!--end row-->

    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->


<!-- Start -->
<section class="section pt-4" style="background-color: rgba(0, 0, 0, 0.05);">
    <div class="container mt-60">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title mb-3">Mausoleum Memorials / Monument Description</h4>
                    <p class="text-muted mx-auto mb-0">A mausoleum, a stately sanctuary of memories, stands as a tribute to lives lived grandly. Built with architectural elegance and enduring materials, it echoes tales of opulence. Each chamber whispers the essence of a cherished soul — names, legacy, and familial ties. These structures epitomize everlasting reverence, providing an eternal home for loved ones, a resplendent haven amidst the hallowed halls of memory.
                    </p>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section>
<!-- End -->


<!-- Back to top -->
<!-- <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a> -->
<!-- Back to top -->
