export class RefCustomer {

	constructor(
		public custFName: string,
		public custLName: string,
		public custPhone: string,
		public custEmail: string,
		public custAddress: string,
		public custCity: string,
		public custState: string,
		public custZip: string,
		public custRelationshipToDeceased: string,
		public isEditing:boolean = false
	) {}

}
