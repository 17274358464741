import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { Booking } from'../objectmodels/booking';

@Injectable({
  providedIn: 'root'
})
export class BookAppointmentsService {

  private sendBookingRequestURL = 'https://www.stone-simple.com/WSLIVE_RICHARDSON/booking/send_booking_request.php';
  private getEventsUrl = 'https://www.stone-simple.com/WSLIVE_RICHARDSON/calendar/get_events.php'; // Update with your server URL
  private createEventUrl = 'https://www.stone-simple.com/WSLIVE_RICHARDSON/calendar/create_event.php'; // Update with your server URL

  constructor(
    private http: HttpClient
  ) { }

  sendBookingRequest(booking:Booking): Observable<Booking> {
    return this.http.post<Booking>(`${this.sendBookingRequestURL}`, { booking: booking })
        .pipe(map((res) => {
          if(res) {
            return res;
          } else {
            return null;
          }
        }));
  }

  getEvents(): Observable<any> {
    return this.http.get<any>(this.getEventsUrl);
  }

  addEvent(event: any): Observable<any> {
    return this.http.post<any>(this.createEventUrl, event);
  }

}