import { Component, OnInit } from '@angular/core';
import { NgxMasonryOptions } from 'ngx-masonry';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-gallery-monument-custom',
  templateUrl: './gallery-monument-custom.component.html',
  styleUrls: ['./gallery-monument-custom.component.css']
})
export class GalleryMonumentCustomComponent implements OnInit {

  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;
  footerVariant = 'footer-four';
  
  private _album = [];
  /***
   * Masonry Option
   */
  public myOptions: NgxMasonryOptions = {
    horizontalOrder: true
  };

  /**
   * Portfolio Masonry Five Data
   */
  filterredImages;
  galleryFilter = 'all';
  list = [
 {
    image: 'custom-us-flag.jpg',
    title: 'Custom Monument US Flag',
    text: 'Flag',
    category: 'Other'
  },{
    image: 'custom-cross-001.jpg',
    title: 'Custom Monument Rough Stone Cross',
    text: 'Cross',
    category: 'Other'
  },{
    image: 'custom-tripdic.jpg',
    title: 'Custom Monument Tripdic',
    text: 'Tripdic',
    category: 'Other'
  },
  {
    image: 'custom-statue-001.jpg',
    title: 'Custom Monument Angel Statue',
    text: 'Angel',
    category: 'Other'
  },
  {
    image: 'custom-mountaintop.jpg',
    title: 'Custom Monument Mountain Tops',
    text: 'Mountain',
    category: 'Other'
  }, {
    image: 'custom-teardrop-004.jpg',
    title: 'Custom Monument',
    text: 'Teardrop',
    category: 'Teardrop'
  },
  {
    image: 'custom-teardrop-001.jpg',
    title: 'Custom Monument',
    text: 'Teardrop',
    category: 'Teardrop'
  },
  {
    image: 'custom-teardrop-002.jpg',
    title: 'Custom Monument',
    text: 'Teardrop',
    category: 'Teardrop'
  },
  {
    image: 'custom-teardrop-003.jpg',
    title: 'Custom Monument',
    text: 'Teardrop',
    category: 'Teardrop'
  },{
    image: 'custom-shamrock-002.jpg',
    title: 'Custom Monument',
    text: 'Shamrock',
    category: 'Shamrock'
  },
  {
    image: 'custom-shamrock-001.jpg',
    title: 'Custom Monument',
    text: 'Shamrock',
    category: 'Shamrock'
  },{
    image: 'custom-heart-001.jpg',
    title: 'Custom Monument',
    text: 'Heart',
    category: 'Heart'
  },
  {
    image: 'custom-heart-002.jpg',
    title: 'Custom Monument',
    text: 'Heart',
    category: 'Heart'
  },
  {
    image: 'custom-heart-003.jpg',
    title: 'Custom Monument',
    text: 'Heart',
    category: 'Heart'
  },
  {
    image: 'custom-heart-004.jpg',
    title: 'Custom Monument',
    text: 'Heart',
    category: 'Heart'
  },
  {
    image: 'custom-heart-005.jpg',
    title: 'Custom Monument',
    text: 'Heart',
    category: 'Heart'
  },
  {
    image: 'custom-heart-006.jpg',
    title: 'Custom Monument',
    text: 'Heart',
    category: 'Heart'
  },
  {
    image: 'custom-heart-007.jpg',
    title: 'Custom Monument',
    text: 'Heart',
    category: 'Heart'
  },
  {
    image: 'custom-heart-008.jpg',
    title: 'Custom Monument',
    text: 'Heart',
    category: 'Heart'
  },
  {
    image: 'custom-heart-009.jpg',
    title: 'Custom Monument',
    text: 'Heart',
    category: 'Heart'
  },
  {
    image: 'custom-heart-010.jpg',
    title: 'Custom Monument',
    text: 'Heart',
    category: 'Heart'
  },
  {
    image: 'custom-heart-011.jpg',
    title: 'Custom Monument',
    text: 'Heart',
    category: 'Heart'
  },
  {
    image: 'custom-heart-012.jpg',
    title: 'Custom Monument',
    text: 'Heart',
    category: 'Heart'
  },
  {
    image: 'custom-heart-013.jpg',
    title: 'Custom Monument',
    text: 'Heart',
    category: 'Heart'
  },{
    image: 'custom-art-deco-002.jpg',
    title: 'Custom Monument',
    text: 'Art Deco',
    category: 'Art Deco'
  },
  {
    image: 'custom-art-deco-001.jpg',
    title: 'Custom Monument',
    text: 'Art Deco',
    category: 'Art Deco'
  }, {
    image: 'custom-angel-004.jpg',
    title: 'Custom Monument',
    text: 'Angel',
    category: 'Angel'
  },
  {
    image: 'custom-angel-001.jpg',
    title: 'Custom Monument',
    text: 'Angel',
    category: 'Angel'
  },
  {
    image: 'custom-angel-002.jpg',
    title: 'Custom Monument',
    text: 'Angel',
    category: 'Angel'
  },
  {
    image: 'rock-finish-001.jpg',
    title: 'Custom Monument',
    text: 'Rock Finish',
    category: 'Rock Finish'
  },
  {
    image: 'rock-finish-002.jpg',
    title: 'Custom Monument',
    text: 'Rock Finish',
    category: 'Rock Finish'
  },
  {
    image: 'sculpture-001.jpg',
    title: 'Custom Monument',
    text: 'Sculpture',
    category: 'Sculpture'
  },
  {
    image: 'sculpture-002.jpg',
    title: 'Custom Monument',
    text: 'Sculpture',
    category: 'Sculpture'
  },
  {
    image: 'sculpture-003.jpg',
    title: 'Custom Monument',
    text: 'Sculpture',
    category: 'Sculpture'
  },
  {
    image: 'sculpture-004.jpg',
    title: 'Custom Monument',
    text: 'Sculpture',
    category: 'Sculpture'
  },
  ];

  constructor(private _lightbox: Lightbox) {}

  ngOnInit(): void {
    this.filterredImages = this.list;
    this.resortImages();
  }

  resortImages() {
    this._album = [];
    for (let i = 0; i <= this.filterredImages.length; i++) {
      const src = 'assets/images/monuments/' + this.filterredImages[i].image;
      const caption = 'Image ' + this.filterredImages[i].title;
      const thumb = 'assets/images/monuments/' + this.filterredImages[i].image;
      const item = {
        src: src,
        caption: caption,
        thumb: thumb
      };
      this._album.push(item);
    }
  }

  activeCategory(category) {
    this.galleryFilter = category;
    if (this.galleryFilter === 'all') {
      this.filterredImages = this.list;
      this.resortImages();
    } else {
      this.filterredImages = this.list.filter(x => x.category === this.galleryFilter);
      this.resortImages();
    }
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._album, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

}
