<div class="card rounded shadow p-4 border-0">
  <form class="needs-validation" name="customerForm" #customerForm="ngForm">
    <div class="row g-3">
      <div class="col-sm-6">
        <div *ngIf="custFName.invalid"><label for="custFName" class="form-label">Customer First Name  <span style="color:#7F1517;">*REQUIRED</span></label></div>
        <div *ngIf="custFName.valid"><label for="custFName" class="form-label">Customer First Name  <span style="color:green;">*VALID</span></label></div>
        <input type="text" required #custFName="ngModel" name="custFName" pattern="^[a-zA-Z\s]*$" placeholder="First Name" ngModel class="form-control" minlength="3" />
      </div>

      <div class="col-sm-6">
        <div *ngIf="custLName.invalid"><label for="custLName" class="form-label">Customer Last Name  <span style="color:#7F1517;">*REQUIRED</span></label></div>
        <div *ngIf="custLName.valid"><label for="custLName" class="form-label">Customer Last Name  <span style="color:green;">*VALID</span></label></div>
        <input type="text" required #custLName="ngModel" name="custLName" pattern="^[a-zA-Z\s]*$" placeholder="Customer Last Name" ngModel class="form-control" minlength="3" />
      </div>

      <div class="col-sm-6">
        <div *ngIf="custEmail.invalid"><label for="custEmail" class="form-label">Customer Email </label></div>
        <div *ngIf="custEmail.valid"><label for="custEmail" class="form-label">Customer Email </label></div>
        <input type="email" #custEmail="ngModel" name="custEmail" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" placeholder="customer email here" ngModel class="form-control" minlength="6" />
      </div>

      <div class="col-sm-6">
        <label for="custPhone" class="form-label">Customer Phone</label>
        <input type="text" #custPhone="ngModel" name="custPhone" placeholder="111-111-1111" ngModel class="form-control" minlength="10" />
      </div>

      <div class="col-sm-12">
        <label for="custRelationshipToDeceased" class="form-label">Customer relationship to deceased</label>
        <input type="text" #custRelationshipToDeceased="ngModel" name="custRelationshipToDeceased" pattern="^[a-zA-Z\s]*$" placeholder="Husband, Wife, Daughter, Uncle etc." ngModel class="form-control" minlength="3" />
      </div>

      <div class="col-12">
        <label for="custAddress" class="form-label">Customer Address</label>
        <input type="text" class="form-control" id="custAddress" name="custAddress" placeholder="1234 Main St">
      </div>

      <div class="col-sm-5">
        <label for="custCity" class="form-label">Customer City</label>
        <input type="text" class="form-control" id="custCity" name="custCity" placeholder="City" value="">
      </div>

      <div class="col-md-4">
        <label for="custState" class="form-label">Customer State</label>
        <select class="form-select form-control" id="custState">
          <option value="">Choose...</option>
          <ng-container *ngIf="states"><option *ngFor="let st of states" value="{{st.abbreviation}}">{{st.name}}</option></ng-container>
        </select>
      </div>

      <div class="col-md-3">
        <label for="custZip" class="form-label">Customer Zip</label>
        <input type="text" #custZip="ngModel" name="custZip" id="custZip" pattern="^[0-9]*$" placeholder="00000" ngModel class="form-control" minlength="5" />
      </div>
      <!--end col-->
    </div>
    <button class="w-100 btn btn-primary" style="margin-top: 20px;" type="submit" (click)="updateCustomer()" [disabled]="customerForm.invalid">NEXT</button>
    <!--end row-->
  </form>
  <!--end form-->
</div>