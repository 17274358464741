import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-index-richardson-monument',
  templateUrl: './index-richardson-monument.component.html',
  styleUrls: ['./index-richardson-monument.component.css']
})
export class IndexRichardsonMonumentComponent implements OnInit {

  /***
   * Nav Bg Light Class Add
   */
  navClass = 'navbar-trans-bg';
  // navClass = 'navbar-white-bg';
  // navClass = 'nav-light';
  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;
  footerVariant = 'footer-four';

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
