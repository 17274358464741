<!-- Start Contact -->
<section class="section pt-5 mt-4" id="home">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 p-0">
        <div class="card map border-0">
          <div class="card-body p-0">
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2952.071515374429!2d-71.05430778674199!3d42.27699674022264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e37b804f15565f%3A0x3861ddcea0271d35!2s2%20Granite%20Ave%20Suite%20260%2C%20Milton%2C%20MA%2002186!5e0!3m2!1sen!2sus!4v1711993192792!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60" style="background-color: rgba(0, 0, 0, 0.05);padding: 20px;">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <div class="card custom-form rounded border-0 shadow p-4">
          <form method="post" name="myForm">
            

            <ng-container *ngIf="sendingMessage">
              <div id="simple-msg"><p id="error-msg" class="mb-0">{{sendingMessage}}</p></div>
            </ng-container>

            <div class="row" *ngIf="showMessageForm">
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">Your Name <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <i-feather name="user" class="fea icon-sm icons"></i-feather>
                    <input name="name" id="name" type="text" class="form-control ps-5" placeholder="Name :">
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">Your Email <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                    <input name="email" id="email" type="email" class="form-control ps-5" placeholder="Email :">
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-12">
                <div class="mb-3">
                  <label class="form-label">Phone</label>
                  <div class="form-icon position-relative">
                    <i-feather name="phone" class="fea icon-sm icons"></i-feather>
                    <input name="phone" id="phone" class="form-control ps-5" placeholder="phone :">
                  </div>
                </div>
              </div>
              <!--end col-->                

              <div class="col-12">
                <div class="mb-3">
                  <label class="form-label">Subject</label>
                  <div class="form-icon position-relative">
                    <i-feather name="book" class="fea icon-sm icons"></i-feather>
                    <input name="subject" id="subject" class="form-control ps-5" placeholder="subject :">
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-12">
                <div class="mb-3">
                  <label class="form-label">Comments <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                    <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                      placeholder="Message :"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="showMessageForm">
              <div class="col-12">
                <div class="d-grid">
                  <button type="submit" id="submit" name="send" class="btn btn-primary" (click)="validateForm()" [disabled]="mesgBTNdisabled">Send Message</button>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->




          </form>
        </div>
        <!--end custom-form-->
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 order-1 order-md-2">
        <div class="title-heading ms-lg-4">
          <h4 class="mb-4">LOCATION: <span style="color:#7F1517;">Milton, Massachusetts</span></h4>
          <p class="text-muted">2 Granite Ave Suite 260, <br>Milton, MA 02186</p>
          <p class="text-muted">
            <strong>HOURS</strong><br>				
            Monday - Friday <strong>9am - 5pm</strong><br>
            <strong>Saturday - By Appointment Only</strong>
          </p>
          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather name="phone" class="fea icon-m-md text-dark me-3"></i-feather>
            </div>
            <div class="flex-1 content">
              <h6 class="title fw-bold mb-0">Phone</h6>
              <a href="tel:+19788805252" class="text-primary">978-880-5252</a>
            </div>
          </div>
		      <button type="submit" id="submit" name="send" class="btn btn-primary" (click)="bookAppointment($event)">BOOK APPOINTMENT</button>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End contact -->
