<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" style="background: url('assets/images/cem01.jpg');" id="home">
  <!-- <div class="bg-overlay"></div> -->
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading title-heading">
          <h2 class="text-white title-dark"> Onsite Engraving </h2>
          <p class="text-white-50 mb-0 mx-auto">Onsite Engraving: Our skilled craftsmen bring the essence of remembrance to life, on location. With precision tools and a delicate touch, we etch lasting memories into the chosen surface. Whether it's a name, a date, or a heartfelt message, we carefully inscribe each detail, ensuring a permanent tribute. Experience the artistry and dedication as personalized engravings honor the legacy, forever etched in the very heart of the memorial site.</p>
        </div>
      </div><!--end col-->
    </div><!--end row-->
  </div> <!--end container-->
</section><!--end section-->

<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->


<section class="section bg-light">
  <div class="container">
    <div class="d-flex">
      <ul ngbNav #nav="ngbNav" orientation="vertical" class="nav-pills rounded shadow p-3 mb-0 mt-4 col-md-4 pt-2">
        <li ngbNavItem="top">
          <a ngbNavLink class="rounded">
            <div class="text-center pt-1 pb-1">
              <h6 class="mb-0">FOSTER <span style="color:red;font-weight: bold;">BEFORE</span></h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <div class="show active p-4 rounded shadow" id="developing" role="tabpanel" aria-labelledby="webdeveloping">
              <img src="assets/images/monuments/FosterBefore.jpg" class="img-fluid rounded shadow" alt="">
              <div class="mt-4">
                <button routerLink="/page-book-appointment" type="submit" id="submit" name="send" class="btn btn-primary">SCHEDULE AN ENGRAVING APPOINTMENT</button>
              </div>
            </div>
          </ng-template>
        </li>
        <li ngbNavItem>
          <a ngbNavLink class="rounded">
            <div class="text-center pt-1 pb-1">
                <h6 class="mb-0">FOSTER <span style="color:green;font-weight: bold;">AFTER</span></h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <div class="p-4 rounded shadow" id="data-analise" role="tabpanel" aria-labelledby="database">
              <img src="assets/images/monuments/FosterAfter.jpg" class="img-fluid rounded shadow" alt="">
              <div class="mt-4">
                <button routerLink="/page-book-appointment" type="submit" id="submit" name="send" class="btn btn-primary">SCHEDULE AN ENGRAVING APPOINTMENT</button>
              </div>
            </div>
            <!--end teb pane-->
          </ng-template>
        </li>
        <hr>
        <li ngbNavItem>
          <a ngbNavLink class="rounded">
            <div class="text-center pt-1 pb-1">
              <h6 class="mb-0">MOODY <span style="color:red;font-weight: bold;">BEFORE</span></h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <div class="show active p-4 rounded shadow" id="developing" role="tabpanel" aria-labelledby="webdeveloping">
              <img src="assets/images/monuments/MoodyBefore.jpg" class="img-fluid rounded shadow" alt="">
              <div class="mt-4">
                <button routerLink="/page-book-appointment" type="submit" id="submit" name="send" class="btn btn-primary">SCHEDULE AN ENGRAVING APPOINTMENT</button>
              </div>
            </div>
          </ng-template>
        </li>
        <li ngbNavItem>
          <a ngbNavLink class="rounded">
            <div class="text-center pt-1 pb-1">
                <h6 class="mb-0">MOODY <span style="color:green;font-weight: bold;">AFTER</span></h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <div class="p-4 rounded shadow" id="data-analise" role="tabpanel" aria-labelledby="database">
              <img src="assets/images/monuments/MoodyAfter.jpg" class="img-fluid rounded shadow" alt="">
              <div class="mt-4">
                <button routerLink="/page-book-appointment" type="submit" id="submit" name="send" class="btn btn-primary">SCHEDULE AN ENGRAVING APPOINTMENT</button>
              </div>
            </div>
            <!--end teb pane-->
          </ng-template>
        </li>
        <hr>
        <li ngbNavItem>
          <a ngbNavLink class="rounded">
            <div class="text-center pt-1 pb-1">
              <h6 class="mb-0">JESCHKE <span style="color:red;font-weight: bold;">BEFORE</span></h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <div class="show active p-4 rounded shadow" id="developing" role="tabpanel" aria-labelledby="webdeveloping">
              <img src="assets/images/monuments/JeschkeBefore.jpg" class="img-fluid rounded shadow" alt="">
              <div class="mt-4">
                <button routerLink="/page-book-appointment" type="submit" id="submit" name="send" class="btn btn-primary">SCHEDULE AN ENGRAVING APPOINTMENT</button>
              </div>
            </div>
          </ng-template>
        </li>
        <li ngbNavItem>
          <a ngbNavLink class="rounded">
            <div class="text-center pt-1 pb-1">
                <h6 class="mb-0">JESCHKE <span style="color:green;font-weight: bold;">AFTER</span></h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <div class="p-4 rounded shadow" id="data-analise" role="tabpanel" aria-labelledby="database">
              <img src="assets/images/monuments/JeschkeAfter.jpg" class="img-fluid rounded shadow" alt="">
              <div class="mt-4">
                <button routerLink="/page-book-appointment" type="submit" id="submit" name="send" class="btn btn-primary">SCHEDULE AN ENGRAVING APPOINTMENT</button>
              </div>
            </div>
            <!--end teb pane-->
          </ng-template>
        </li>
        <hr>
        <li ngbNavItem>
          <a ngbNavLink class="rounded">
            <div class="text-center pt-1 pb-1">
              <h6 class="mb-0">DeWEVER <span style="color:red;font-weight: bold;">BEFORE</span></h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <div class="show active p-4 rounded shadow" id="developing" role="tabpanel" aria-labelledby="webdeveloping">
              <img src="assets/images/monuments/DeweverBefore.jpg" class="img-fluid rounded shadow" alt="">
              <div class="mt-4">
                <button routerLink="/page-book-appointment" type="submit" id="submit" name="send" class="btn btn-primary">SCHEDULE AN ENGRAVING APPOINTMENT</button>
              </div>
            </div>
          </ng-template>
        </li>
        <li ngbNavItem>
          <a ngbNavLink class="rounded">
            <div class="text-center pt-1 pb-1">
                <h6 class="mb-0">DeWEVER <span style="color:green;font-weight: bold;">AFTER</span></h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <div class="p-4 rounded shadow" id="data-analise" role="tabpanel" aria-labelledby="database">
              <img src="assets/images/monuments/DeweverAfter.jpg" class="img-fluid rounded shadow" alt="">
              <div class="mt-4">
                <button routerLink="/page-book-appointment" type="submit" id="submit" name="send" class="btn btn-primary">SCHEDULE AN ENGRAVING APPOINTMENT</button>
              </div>
            </div>
            <!--end teb pane-->
          </ng-template>
        </li> 
        <hr>
        <li ngbNavItem>
          <a ngbNavLink class="rounded">
            <div class="text-center pt-1 pb-1">
              <h6 class="mb-0">BICKMORE <span style="color:red;font-weight: bold;">BEFORE</span></h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <div class="show active p-4 rounded shadow" id="developing" role="tabpanel" aria-labelledby="webdeveloping">
              <img src="assets/images/monuments/BickmoreBefore.jpg" class="img-fluid rounded shadow" alt="">
              <div class="mt-4">
                <button routerLink="/page-book-appointment" type="submit" id="submit" name="send" class="btn btn-primary">SCHEDULE AN ENGRAVING APPOINTMENT</button>
              </div>
            </div>
          </ng-template>
        </li>
        <li ngbNavItem>
          <a ngbNavLink class="rounded">
            <div class="text-center pt-1 pb-1">
                <h6 class="mb-0">BICKMORE <span style="color:green;font-weight: bold;">AFTER</span></h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <div class="p-4 rounded shadow" id="data-analise" role="tabpanel" aria-labelledby="database">
              <img src="assets/images/monuments/BickmoreAfter.jpg" class="img-fluid rounded shadow" alt="">
              <div class="mt-4">
                <button routerLink="/page-book-appointment" type="submit" id="submit" name="send" class="btn btn-primary">SCHEDULE AN ENGRAVING APPOINTMENT</button>
              </div>
            </div>
            <!--end teb pane-->
          </ng-template>
        </li> 

      </ul>
      <div [ngbNavOutlet]="nav" class="mt-4 ms-4"></div>
    </div>
    <!--end col-->

    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->


<!-- Back to top -->
<!-- <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a> -->
<!-- Back to top -->