<!-- START ADD CEMETERY FORM  -->
<ng-container *ngIf="addCemetery">
    <div class="card rounded shadow p-4 border-0">
      <form class="needs-validation" name="cemeteryForm" #cemeteryForm="ngForm">
        <div class="row g-3">
          <div class="col-sm-12">
            <div *ngIf="cemeteryName.invalid"><label for="cemeteryName" class="form-label">Cemetery Name <span style="color:#7F1517;">*REQUIRED</span></label></div>
            <div *ngIf="cemeteryName.valid"><label for="cemeteryName" class="form-label">Cemetery Name <span style="color:green;">*VALID</span></label></div>
            <input type="text" required #cemeteryName="ngModel" name="cemeteryName" pattern="^[a-zA-Z\s]*$" placeholder="Cemetery Name" ngModel class="form-control" minlength="3" />
          </div>

          <div class="col-sm-6">
            <label for="cemeteryEmail" class="form-label">Cemetery Email</label>
            <input type="email" #cemeteryEmail="ngModel" name="cemeteryEmail" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" placeholder="your@email.com" ngModel class="form-control" minlength="6" />
          </div>
          <div class="col-sm-6">    
            <label for="cemeteryPhone" class="form-label">Cemetery Phone</label>
            <input type="text" #cemeteryPhone="ngModel" name="cemeteryPhone" placeholder="111-111-1111" ngModel class="form-control" minlength="10" />
          </div>
          <!--end col-->

          <div class="col-12">
            <label for="cemeteryAddressOne" class="form-label">Cemetery Address</label>
            <input type="text" #cemeteryAddressOne="ngModel" name="cemeteryAddressOne" pattern="^[a-zA-Z\s]*$" placeholder="Cemetery Address" ngModel class="form-control" minlength="3" />
          </div>
          <!--end col-->

          <div class="col-sm-5">
            <div *ngIf="cemeteryCity.invalid"><label for="cemeteryCity" class="form-label">Cemetery City <span style="color:#7F1517;">*REQUIRED</span></label></div>
            <div *ngIf="cemeteryCity.valid"><label for="cemeteryCity" class="form-label">Cemetery City <span style="color:green;">*VALID</span></label></div>
            <input type="text" required #cemeteryCity="ngModel" name="cemeteryCity" pattern="^[a-zA-Z\s]*$" placeholder="Cemetery City" ngModel class="form-control" minlength="3" />
          </div>

          <div class="col-md-4">
            <label for="cemeteryState" class="form-label">Cemetery State <span style="color:#7F1517;">*REQUIRED</span></label>
            <select class="form-select form-control" id="cemeteryState">
              <option value="">Choose...</option>
              <ng-container *ngIf="states"><option *ngFor="let st of states" value="{{st.abbreviation}}">{{st.name}}</option></ng-container>
            </select>            
          </div>

          <div class="col-md-3">
            <label for="cemeteryZip" class="form-label">Cemetery Zip</label>
            <input type="text" #cemeteryZip="ngModel" name="cemeteryZip" pattern="^[0-9]*$" placeholder="00000" ngModel class="form-control" minlength="5" />
          </div>
          <!--end col-->
        </div>
        <button class="w-100 btn btn-primary" style="margin-top: 20px;" type="submit" (click)="updateCemetery()" [disabled]="cemeteryForm.invalid">NEXT</button>
        <!--end row-->
      </form>
      <!--end form-->
    </div>
</ng-container>
<!-- END ADD CEMETERY FORM  -->
<!-- START SEARCH CEMETERY AREA  -->
<div class="card rounded shadow p-4 border-0" style="background-color: #7F1517;">
<div class="text-center subcribe-form">
    <!-- SPINNER -->
    <ng-container *ngIf="searching">
        <div class="spinner">
            <svg id="a" xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39"><circle cx="19.5" cy="19.5" r="9" fill="#ffffff" opacity=".1"/><g opacity=".8"><circle cx="19.5" cy="2.8" r="2.8" fill="#ffffff"/></g><g opacity=".4"><circle cx="19.5" cy="36.2" r="2.8" fill="#ffffff"/></g><g opacity=".9"><circle cx="31.31" cy="7.69" r="2.8" fill="#ffffff"/></g><g opacity=".5"><circle cx="7.69" cy="31.31" r="2.8" fill="#ffffff"/></g><circle cx="36.2" cy="19.5" r="2.8" fill="#ffffff"/><g opacity=".6"><circle cx="2.8" cy="19.5" r="2.8" fill="#ffffff"/></g><g opacity=".3"><circle cx="31.31" cy="31.31" r="2.8" fill="#ffffff"/></g><g opacity=".7"><circle cx="7.69" cy="7.69" r="2.8" fill="#ffffff"/></g></svg>
        </div>
    </ng-container> 
    <!-- END SPINNER -->                       
    <form>
        <input type="text" id="searchForTerm" name="searchForTerm" [(ngModel)]="searchForTerm" class="rounded-pill bg-white-50" placeholder="Search for your cemetery by name...">
        <button type="submit" class="btn btn-pills btn-primary" (click)="initialSearch()" [disabled]="searchForTerm == null || searchForTerm.length <= 1">Search <i class="uil uil-arrow-right"></i></button>
    </form><!--end form-->

<ng-container *ngIf="noresults">
    <h6 style="color:white;margin:20px 0;"> Sorry, we did not find your cemetery. Please try again with different criteria or click ADD NEW below to add your cemetery.</h6>
    <a href="#" class="btn btn-pills btn-primary" (click)="!!addCem()"> ADD NEW </a>
</ng-container>

    <ng-conatainer *ngIf="selectedCemetery">
        {{selectedCemetery.cemeteryPKID}} - {{selectedCemetery.cemeteryName}}
        <a href="#" class="btn btn-pills btn-primary" (click)="!!addCem()"> ADD NEW </a>
    </ng-conatainer>
    <!-- CEMETERIES RESULTS -->
        <ng-container *ngIf="cemeteries">
        <div class="table-responsive bg-white shadow rounded mt-4">
            <h6 style="text-align:center;margin:14px;"><span style="font-weight: bold;">{{cemeteries.length}} FOUND </span><span style="color:red;">- If you dont see your cemetery in the list below click the button below to add it.</span></h6>
            <a href="#" class="btn btn-pills btn-primary" (click)="!!addCem()"> ADD CEMETERY </a>
            <table class="table table-center">
                <thead>
                    <tr class="bg-light">
                        <th scope="col" class="border-bottom tbhd" style="min-width: 250px;">Name</th>
                        <th scope="col" class="border-bottom tbhd text-left" style="min-width: 80px;">Address</th>
                        <th scope="col" class="border-bottom tbhd text-center" style="min-width: 80px;">Contact</th>
                        <th scope="col" class="border-bottom tbhd text-center" style="min-width: 100px;">Link</th>
                    </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let cem of cemeteries">
                    <tr>
                        <th class="red-txt">{{cem.cemeteryName}}</th>
                        <td class=" text-left">{{cem.cemeteryAddressOne}} {{cem.cemeteryCity}}, {{cem.cemeteryState}}. {{cem.cemeteryZip}}</td>
                        <td class=" text-center">
                            <ng-container *ngIf="cem.cemeteryPhone">
                                {{cem.cemeteryPhone}}
                            </ng-container>
                            <ng-container *ngIf="!cem.cemeteryPhone">
                                ...
                            </ng-container>
                        </td>
                        <td class=" text-center">
                            <a href="#" class="btn btn-pills btn-primary" (click)="!!selectCem(cem)"> CHOOSE </a>
                        </td>
                    </tr>
                </ng-container>
                </tbody>
            </table>
            <!--end table-->
        </div>
        </ng-container>
</div>
</div><!-- START SEARCH CEMETERY AREA  -->