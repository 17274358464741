import { Component, OnChanges, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ReferralService } from '../../../../services/referral.service';
import { CookieService } from 'ngx-cookie-service';

import { RefContact } from '../../../../objectmodels/ref-contact';
import { Referral } from '../../../../objectmodels/referral';

import * as data from '../../../../../assets/states.json';

@Component({
  selector: 'app-referral-contact',
  templateUrl: './referral-contact.component.html',
  styleUrls: ['./referral-contact.component.css']
})

export class ReferralContactComponent implements OnInit {

  referral:Referral=null;
  states: any = (data as any).default;

  @Input() refID : number;
  @Input() refTOKEN : string;
  @Output() referralCreated = new EventEmitter<Referral>();

  constructor(
    public referralService: ReferralService,
    private cookieService: CookieService
  ) {
    const currentRef = new Referral(
      this.refID,
      this.refTOKEN,
      ""
    );
    this.referral = currentRef;
  }

  ngOnInit(): void {
  
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.refID) {
      const currentRef = new Referral(
        this.refID,
        this.refTOKEN,
        ""
      );
      this.referral = currentRef;
      // console.dir(this.referral);
    }

    if (changes.refTOKEN) {
      const currentRef = new Referral(
        this.refID,
        this.refTOKEN,
        ""
      );
      this.referral = currentRef;
      // console.dir(this.referral);
    }
  }    

  saveReferralContact() {
    let refFName = document.forms["myForm"]["refFName"].value;
    let refLName = document.forms["myForm"]["refLName"].value;
    let refBusinessName = document.forms["myForm"]["refBusinessName"].value;
    let refPhone = document.forms["myForm"]["refPhone"].value;
    let refEmail = document.forms["myForm"]["refEmail"].value;
    let refAddress = document.forms["myForm"]["refAddress"].value;
    let refCity = document.forms["myForm"]["refCity"].value;
    let refState = document.forms["myForm"]["refState"].value;
    let refZip = document.forms["myForm"]["refZip"].value;
    const refContact = new RefContact(
      refFName,
      refLName,
      refBusinessName,
      refPhone,
      refEmail,
      refAddress,
      refCity,
      refState,
      refZip,
      false
    );
    if (this.refID && this.refTOKEN) {
      this.referralService.updateReferralContact(this.referral, refContact)
      .subscribe(results => {
        if (results) {
          this.referralCreated.emit(this.referral);
        }
      });
    } else {
      this.referralService.createNewReferral(refContact)
      .subscribe(results => {
        if (results) {
          this.referral = results;
          this.referralCreated.emit(this.referral);
        }
      });
    }
  }

}